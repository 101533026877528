import i18n from "i18next";
import { initReactI18next } from "react-i18next";


export const initI18n = async () => {
  const ruRU = await import("./translation.ru_RU.json");
  const enGB = await import("./translation.en_GB.json");

  const resources = {
    en: enGB,
    ru: ruRU
  };

  await i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    ns: "default",
    fallbackNS: "default",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false
    }
  });
};
