import React, { FC } from "react";

import styled from "styled-components";
import { TSliderItem } from "src/types";
import { BackgroundColors, Colors } from "src/constants";
import { Txt } from "src/ui/text";

type TProps = TSliderItem & Readonly<{
    onClick: (value: string) => void;
    isActive: boolean;
}>;

interface ButtonProps {
    bgColor: string;
}

const Button = styled.button<ButtonProps>`
    display: block;
    border-radius: 30px;
    min-height: 34px;
    width: calc(100% - 10px);
    background-color: ${({ bgColor }) => bgColor};
`
const SliderButtonItem_: FC<TProps> = ({
    isActive,
    label,
    value,
    onClick,
}: TProps) => {
    const click = React.useCallback(
        () => {
            onClick(value);
        },
        [onClick, value],
    );

    const colorFinal = React.useMemo<string>(
        () => {
            if (!label) {
                return "unset";
            }
            return isActive ? BackgroundColors.bgWhite : Colors.sysBlue1;
        },
        [isActive, label],
    );

    return (
            <Button 
                bgColor={isActive ? Colors.sysBlue1 : Colors.sysBlue5} 
                onClick={click}
            >
                <Txt textStyle="body_14" color={colorFinal}>{label}</Txt>
            </Button>
    );
};

export const SliderButtonItem = React.memo(SliderButtonItem_);
