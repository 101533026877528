import { string } from "yup";

export const usernameSchema = string()
  .required("Required field")
  .email("Unvalid email");

export const passwordSchema = string()
  .required("Required field")
  .min(6, "6 characters minimum")
  .max(32, "32 characters maximum");

export const pageNameSchema = string()
  .required("Required field")
  .matches(/^[A-Z]+$/i,'URL is not valid');

export const urlRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
