import { RegisterRequest } from "src/types";
import { APIClient } from "../../APIClient";
import { APIResponse } from "../../models/APIResponse";
import { TGetUserResponse } from "../../models/GetUserResponse";
import { LoginRequest } from "../../models/LoginRequest";
import { LoginResponse } from "../../models/LoginResponse";
import { RegisterResponse } from "../../models/RegisterResponse";
import { getAccessToken } from "../utils/get-access-token";
import { TActivateEmailResponse, TResendVerificationLinkResponse } from "./types";

export namespace AuthService {
  export function login(model: LoginRequest) {
    return APIClient.request<
      LoginRequest,
      APIResponse<LoginResponse>
    >({
      method: "POST",
      url: "/api/login",
      data: model
    });
  };

  export function register(model: RegisterRequest) {
    return APIClient.request<
      RegisterRequest,
      APIResponse<RegisterResponse>
    >({
      method: "POST",
      url: "/api/register",
      data: model
    });
  };

  export function getUsers() {
    const token: string = getAccessToken();
    
    return APIClient.request<
        {},
        TGetUserResponse
    >({
        method: "GET",
        url: "/cm/users",
        headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    });
  };

  export function resendVerificationLink() {
    const token: string = getAccessToken();
    return APIClient.request<
      {},
      TResendVerificationLinkResponse
    >({
      method: "POST",
      url: "/api/resend",
      data: {},
      headers: {
        "Content-Type": "application/problem+json",
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      },
    });
  };

  export function activateEmail(hashcode: string) {
    return APIClient.request<
      {},
      TActivateEmailResponse
    >({
      method: "GET",
      url: `/api/activation?hash=${hashcode}`,
      headers: {
          "Content-Type": "application/problem+json",
          "Accept": "application/json",
      },
    });
  };
};
