import c from "classnames";
import React, { FC, useState, ChangeEvent, FocusEvent, HTMLInputTypeAttribute, useRef } from "react";

import LocalImage from "src/ui/images/LocalImage";

import s from "./style.module.sass";

type Props = {
  name: string;
  type?: HTMLInputTypeAttribute;
  label: string;
  value: string;
  error?: string;
  disabled?: boolean;
  className?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange: ((e: ChangeEvent<HTMLInputElement>) => void) | null;
};

const Input: FC<Props> = ({
  name,
  type = "text",
  label,
  value,
  error,
  disabled = false,
  className,
  onBlur,
  onChange,
}) => {
  const input = useRef<HTMLInputElement>(null);

  const [isActive, setActive] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);

  // MARK: Label classes

  const labelActiveClass = (() => {
    if (isActive === false && value.length !== 0) {
      return s.labelActive;
    }

    if (isActive) {
      return s.labelActive;
    }
  })();

  const labelErrorClass = (() => {
    if (error) {
      return s.labelError;
    }
  })();

  const labelDisabledClass = (() => {
    if (disabled) {
      return s.labelDisabled;
    }
  })();

  // MARK: Input classes

  const inputErrorClass = (() => {
    if (error) {
      return s.inputError;
    }
  })();

  const inputDisabledClass = (() => {
    if (disabled) {
      return s.inputDisabled;
    }
  })();

  const inputPasswordClass = (() => {
    if (type === "password") {
      return s.inputPassword;
    }
  })();

  const inputType: HTMLInputTypeAttribute = (() => {
    switch (type) {
      case "password":
        return isShowPassword ? "text" : "password";
      case "tel":
        return type;
      case "email":
        return type;
      default:
        return "text";
    }
  })();

  const _onFocus = () => {
    setActive(true);
  };

  const _onBlur = (e: FocusEvent<HTMLInputElement>) => {
    setActive(false);

    if (onBlur !== undefined && value.length !== 0) {
      onBlur(e);
    }
  };

  const _onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShowPassword((value) => !value);
  };

  const passwordElement = (() => {
    if (type === "password") {
      return (
        <div
          className={s.passwordItem}
          onClick={(e) => _onClick(e)}
        >
          <LocalImage
            className={s.icon}
            src={ isShowPassword ? "hidePassword.svg" : "showPassword.svg" }
            alt={ isShowPassword ? "hide" : "show" }
          />
        </div>
      );
    } else {
      return null;
    }
  })();

  return (
    <div className={c(s.container, className)}>
      <input
        ref={input}
        name={name}
        type={inputType}
        value={value}
        disabled={disabled}
        className={c(s.input, inputErrorClass, inputDisabledClass, inputPasswordClass)}
        onBlur={_onBlur}
        onFocus={_onFocus}
        onChange={onChange ?? undefined}
      />
      <label
        htmlFor={name}
        className={c(s.label, labelActiveClass, labelErrorClass, labelDisabledClass)}
      >{ error ?? label }</label>
      { passwordElement }
    </div>
  );
};

export default Input;
