import React from "react";
import { TextColors } from "src/constants";
import { TSelectItem } from "src/types";
import styled from "styled-components";
import { Txt } from "../text";

type TProps = TSelectItem & Readonly<{
    onClick: (value: string) => void;
}>;

interface IItemProps {
    fontFamily?: string;
}

const Item = styled.button<IItemProps>`
    width: 100%;
    height: 50px;
    background-color: transparent;
    font-family: ${({ fontFamily }) => fontFamily};
`

const SelectorItem_: React.FC<TProps> = ({
    label,
    value,
    style,
    onClick
}: TProps) => {
    const colorFinal: string = value.length === 0 ? TextColors.dark3 : TextColors.dark1;

    const click = React.useCallback(
        () => {
            onClick(value);
        },
        [onClick, value],
    );
    
    return (
        <Item onClick={click} fontFamily={style?.fontFamily}>
            <Txt textStyle="body_16" textAlign="start" color={colorFinal}>{label}</Txt>
        </Item>
    )
};

export const SelectorItem = React.memo(SelectorItem_);
