import React, {FC} from "react";

import { Navigate, useParams } from "react-router-dom";
import { BackgroundColors, DefaultReleasedCard, ScreenSizes } from "src/constants";
import { useIsMounted } from "src/hooks/useIsMounted";
import Page404 from "src/pages/Page404";
import { AuthService } from "src/services/api/services/AuthService";
import { CardService } from "src/services/api/services/CardService";
import { useProfileStore } from "src/store";
import Logo from "src/ui/images/Logo";
import { Loader } from "src/ui/loader";
import styled from "styled-components";
import s from "./style.module.sass";
import { TRegisteredCard } from "src/types";

type TCard = Readonly<{
  cardId: number;
  item: TRegisteredCard | null;
  currentUserId: number | null;
  isActive?: boolean;
  isLinkedToPage?: boolean; // is linked to specific page (not same as profile)
  pageAddress?: string;
}>;

const LogoContainer = styled.header`
  display: none;
  @media (min-width: ${ScreenSizes.lg}) {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 102px;
    padding: 20px;
  }
`
const Main = styled.div`
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
  background-color: ${BackgroundColors.bgDarkWhite};
`
const CardRoute_: FC = () => {
    const {id} = useParams();
    const isMounted = useIsMounted();
    const selectCard = useProfileStore((state) => state.selectCard);
    const [card, setCard] = React.useState<TCard | null>(null);

    const loadCardInfo = React.useCallback<(userId: number | null) => void>(
      userId => {
        if (!id) {
          return;
        }
        (async () => {
          try {
            const { request } = CardService.getCard(+id);
            const response = await request();

            if (response.status !== 200) { // case for non-registered card
              throw new Error();
            }

            if (isMounted()) {
              const {page_address, users_id, key} = response.data;

              const isLinkedToPage = !!(page_address && page_address.length > 0);
              const isActive = !!(users_id && users_id.length > 0);

              setCard({
                cardId: +key,
                isActive,
                isLinkedToPage: isLinkedToPage,
                pageAddress: isLinkedToPage ? page_address : undefined,
                item: response.data,
                currentUserId: userId
              });

              if (!isActive && +key > 0) {
                selectCard({...DefaultReleasedCard, ...response.data});
              }
              // TODO: Might be needed for Linking url address to card
              // if (userId === null || userId < 0 || (isActive && (userId !== +users_id))) {
              //   return;
              // }
              // if (!isLinkedToPage) { 
              //   selectCard({...DefaultReleasedCard, ...response.data});
              //   navigate('/admin');
              // }
            }
          } catch (Error) {
            if (isMounted()) {
              setCard({cardId: -1, item: null, currentUserId: null });
              selectCard(null);
            }
          }
        })();
      },
      [id, isMounted, selectCard]
  );

  React.useEffect(
      () => {
        (async () => {
          try {
            const { request } = AuthService.getUsers();
            const response = await request();
  
            if (response.status !== 200) {
              throw new Error();
            }
  
            const {users} = response.data._embedded;
            if (users.length > 1) {
              throw new Error();
            }
  
            if (isMounted()) {
              loadCardInfo(+users[0].user_id);
            }
          } catch (Error) {
            if (isMounted()) {
              loadCardInfo(null);
            }
          }
        })();
      }, [isMounted, loadCardInfo],
  );
  
    if (!card) {
      return (
        <Main>
          <LogoContainer>
            <Logo className={s.logo} />
          </LogoContainer>
          <Loader marginTop={105} />
        </Main>
      );
    }

    if (card.cardId === -1) {
      return (
        <Page404 />
      );
    }

    if (card.isLinkedToPage) {
      window.location.replace(`${card.pageAddress}`);
      return null;
    }

    if (!card.isActive && card.cardId > 0 && card.currentUserId === null) {
      return (
        <Navigate to="/signup" />
      );
    }

    return (
      <Navigate to="/admin" />
    );
  };
  
  export const CardRoute = CardRoute_;
