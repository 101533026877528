import React from "react";

import { ReactSortable, Sortable, Store } from "react-sortablejs";
import { useFlag } from "src/hooks/useFlag";
import { TPageBlockDraggableList, TBlockPositionList } from "src/types";
import { SimpleBlock } from "../../../components/blocks/SimpleBlock";
import styles from "./style.module.sass";

type TProps = Readonly<{
    isSaving: boolean;
    blocks: TPageBlockDraggableList;
    onChangeOrder: (value: TPageBlockDraggableList) => void;
    onEdit: (id: number) => void;
    onSort: (positions: TBlockPositionList) => void;
    color?: string;
    fontFamily?: string;
    isTransparent?: boolean;
}>;

const SortableBlocks_: React.FC<TProps> = ({
    isSaving,
    blocks,
    onChangeOrder,
    onEdit,
    onSort,
    color,
    fontFamily,
    isTransparent
}: TProps) => {
    const [isDisabled, setDisabled, undoDisabled] = useFlag(isSaving);

    const setList = React.useCallback<(newState: TPageBlockDraggableList, sortable: Sortable | null, store: Store) => void>(
        (newState) => {
            if (JSON.stringify(newState) !== JSON.stringify(blocks)) {
                onChangeOrder(newState);
            }
        },
        [blocks, onChangeOrder],
    );

    const saveSorting = React.useCallback(
        () => {
            const positions: TBlockPositionList = blocks.map((x, index) => ({
                id: x.block_id,
                block_position: `${index}`
            }));

            onSort(positions);
        },
        [blocks, onSort],
    );

    return (
        <ReactSortable
            animation={200}
            direction="vertical"
            list={blocks}
            setList={setList}
            className={styles.main}
            disabled={isDisabled}
            onSort={saveSorting}
            filter=".ignore-elements"
        >
            {blocks.map((item) =>
                <SimpleBlock
                    key={item.block_id}
                    item={item}
                    onEdit={onEdit}
                    carouselOnOver={setDisabled}
                    carouselOnLeave={undoDisabled}
                    color={color}
                    fontFamily={fontFamily}
                    isTransparent={isTransparent}
                />
            )}
        </ReactSortable>
    );
};

export const SortableBlocks = React.memo(SortableBlocks_);
