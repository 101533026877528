import c from "classnames";
import { FC } from "react";
import { Outlet } from "react-router-dom";

import s from "./style.module.sass";

const AuthLayout: FC = () => {
  return (
    <main className={c(s.main)}>
      <div className={c(s.wrap)}>
        <div className={s.wrapInner}>
          <section className={s.card}>
            <Outlet />
          </section>
        </div>
      </div>
    </main>
  );
};

export default AuthLayout;