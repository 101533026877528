import { FC } from "react";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  label: string;
  isDisabled?: boolean;
  className?: string;
  onClick?: () => void;
};

const LinkButton_: FC<Props> = ({
  label,
  isDisabled = false,
  className,
  onClick,
}) => {
  const disabledClass = (() => {
    if (isDisabled === false) return;
    return s.buttonDisabled;
  })();

  const _onClick = () => {
    if (isDisabled !== false) return;
    if (onClick === undefined) return;

    onClick();
  };

  const titleElement = (
    <p className={c(s.label)}>
      { label }
    </p>
  );

  return (
    <button
      className={c(className, s.button, disabledClass)}
      type="button"
      onClick={_onClick}
    >
      {titleElement}
    </button>
  );
};

export const LinkButton = LinkButton_;
