import React, { FC } from "react";

import c from "classnames";
import styles from "./style.module.sass";
import { useFlag } from "src/hooks/useFlag";
import { TipBlock } from "./TipBlock";
import MainButton from "src/ui/buttons/MainButton";
import { AddSimpleBlock } from "./AddSimpleBlock";
import { useTranslation } from "react-i18next";
import { PageService } from "src/services/api/services/PageService";
import { useNavigate } from "react-router-dom";
import {
    ImageType, PageBlockType, TPageBlockDraggableList, TPageSocialLinkList,
    TPublicPage, TBlockPositionList, TSocialLinkPositionList, IPageBlock,
    IPageBlockDraggable,
} from "src/types";
import { Loader } from "src/ui/loader";
import { SortableBlocks } from "./SortableBlocks";
import { TOnAddBlock, TOnAddLink, TOnUpdateBlock, TOnUpdatePage } from "./types";
import { EditDefaultBlock } from "./EditDefaultBlock";
import { DefaultBlock } from "../../components/blocks/DefaultBlock";
import { SocialLinkService } from "src/services/api/services/SocialLinkService";
import { BlockContainer } from "src/ui/containers/BlockContainer";
import { sortBlocks } from "src/utils/sort-blocks";
import { usePage } from "src/hooks/usePage";
import { useProfileStore } from "src/store";
import { AuthService } from "src/services/api/services/AuthService";
import { useIsMounted } from "src/hooks/useIsMounted";
import { PageDefaultFont } from "src/constants";
import { PreviewPage } from "./PreviewPage";
import { usePageStore } from "src/store/usePage";
import { usePageState } from "src/hooks/usePageState";


const createItem = (block: IPageBlock): IPageBlockDraggable => {
    return { ...block, id: +block.block_id };
};

const addImageAsync = async (blockId: string, image: File): Promise<void> => {
    try {
        const { request } = PageService.addImageToBlock({
            block_id: blockId,
            block_image_url: image,
        });
        const response = await request();
        if (response.status !== 201) {
            throw new Error();
        }
    } catch (Error) {
        //
    }
}

const deleteImageAsync = async (imageId: string): Promise<void> => {
    try {
        const { request } = PageService.deleteBlockImage(imageId);
        const response = await request();

        if (response.status !== 204) {
            throw new Error();
        }
    } catch (Error) {
        //
    }
}


const UserPage_: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        pageName, isPreview, onEndPreview
    } = usePage();
    const isMounted = useIsMounted();

    const userId = useProfileStore((state) => state.user?.user_id) || "-1";
    const selectUser = useProfileStore((state) => state.selectUser);

    const [isTipVisible, , closeTip] = useFlag(true);
    const [isDefaultEdit, openDefaultEdit, closeDefaultEdit] = useFlag();
    const [isAddSimpleVisible, openAddSimpleBlock, closeAddSimpleBlock] = useFlag();

    const [publicPage, setPublicPage] = React.useState<TPublicPage | null>(null);
    const [blocks, setBlocks] = React.useState<TPageBlockDraggableList>([]);
    const [selectedBlock, setSelectedBlock] = React.useState<IPageBlock | null>(null);
    const [isSorting, setIsSorting] = React.useState<boolean>(false);
    const [socialLinks, setSocialLinks] = React.useState<TPageSocialLinkList | null>([]);

    const onPageLoaded = React.useCallback<(selectedState: TPublicPage | null) => void>(
        (selectedState) => {
            if (selectedState === null) {
                setPublicPage(null);
                return;
            }
            const { blocks, _embedded, owner_id } = selectedState;
            if (
                (pageName !== selectedState?.page_address) ||
                (+userId < 0) ||
                (+owner_id !== +userId)
            ) {
                navigate('/admin');
                return;
            }

            if (isMounted()) {
                setPublicPage({
                    ...selectedState,
                    page_font: selectedState.page_font || PageDefaultFont
                });
                const list = blocks.map(createItem);

                setBlocks(
                    sortBlocks(list)
                );
                setSocialLinks(_embedded.social_link);
            }
        },
        [isMounted, navigate, pageName, userId],
    );
    
    const { loadPage } = usePageState({pageName, onPageLoaded});

    const onCloseManageBlock = React.useCallback(
        () => {
            setSelectedBlock(null);
            closeAddSimpleBlock();
        },
        [closeAddSimpleBlock],
    );

    const selectBlock = React.useCallback<(id: number) => void>(
        id => {
            const item = publicPage?.blocks.find(x => x.block_id === `${id}`);
            if (!item) {
                return;
            }
            setSelectedBlock(item);
            openAddSimpleBlock();
        },
        [openAddSimpleBlock, publicPage?.blocks],
    );

    const loadUserPage = React.useCallback(
        () => {
            if (+userId >= 0) {
                loadPage();
                return;
            }
            (async () => {
                try {
                    const { request } = AuthService.getUsers();
                    const response = await request();

                    if (response.status !== 200) {
                        throw new Error();
                    }

                    const { users } = response.data._embedded;
                    if (users.length > 1) {
                        throw new Error();
                    }

                    if (isMounted()) {
                        selectUser(users[0]);

                        if (+users[0].user_id >= 0) {
                            loadPage();
                        }
                    }
                } catch (Error) {
                    navigate('/signin');
                }
            })();
        },
        [isMounted, loadPage, navigate, selectUser, userId]
    );

    React.useEffect(
        () => {
            loadUserPage();
        }, [loadUserPage],
    );

    const addBlock = React.useCallback<TOnAddBlock>(
        data => {
            if (!publicPage) {
                return;
            }
            (async () => {
                try {
                    if (isMounted()) {
                        usePageStore.setState({ page: null });
                    }

                    const { title, description, url, urlText, images, imageOption } = data;
                    const { request } = PageService.addBlock({
                        image_type: imageOption,
                        block_type: PageBlockType.Simple, // TODO: add more block options
                        pages_id: publicPage.id,
                        block_title: title,
                        block_description: description,
                        block_url: url,
                        block_url_text: urlText,
                        block_image: "",
                        block_position: `${blocks.length}`,
                    });
                    const response = await request();
                    if (response.status !== 201) {
                        throw new Error();
                    }

                    if (imageOption !== ImageType.NoImage && images && images.length > 0) {
                        const uploadPromises = images.map(async (file) => await addImageAsync(response.data.id, file));
                        Promise.all(uploadPromises).then(
                            () => {
                                loadPage();
                            }
                        );

                        return;
                    }

                    loadPage();
                } catch (Error) {
                    loadPage();
                }
            })();
        },
        [blocks.length, isMounted, loadPage, publicPage],
    );

    const updateBlock = React.useCallback<TOnUpdateBlock>(
        data => {
            if (!publicPage || !selectedBlock) {
                return;
            }

            (async () => {
                try {
                    if (isMounted()) {
                        usePageStore.setState({ page: null })
                    }

                    const {
                        imageOption, title, description, url, urlText, position,
                        images, removedImagesIds
                    } = data;

                    const { request } = PageService.updateBlock(data.blockId, {
                        image_type: imageOption,
                        block_type: PageBlockType.Simple,
                        pages_id: publicPage.id,
                        block_title: title,
                        block_description: description,
                        block_url: url,
                        block_url_text: urlText,
                        block_image: "",
                        block_position: position,
                        id: data.blockId,
                    });
                    const response = await request();

                    if (response.status !== 200) {
                        throw new Error();
                    }

                    let imagePromises: Array<Promise<void>> = [];

                    if (imageOption !== ImageType.NoImage && images && images.length > 0) {
                        imagePromises = images.map(async (file) => await addImageAsync(data.blockId, file));
                    }

                    if (removedImagesIds.length > 0) {
                        const deletePromises = removedImagesIds.map(async (id) => await deleteImageAsync(id));
                        imagePromises = [...imagePromises, ...deletePromises];
                    }

                    if (imagePromises.length > 0) {
                        Promise.all(imagePromises).then(
                            () => {
                                loadPage();
                            }
                        );
                        return;
                    }

                    loadPage();
                } catch (Error) {
                    loadPage();
                }
            })();
        },
        [isMounted, loadPage, publicPage, selectedBlock],
    );

    const deleteBlock = React.useCallback<(blockId: string) => void>(
        blockId => {
            (async () => {
                try {
                    if (isMounted()) {
                        usePageStore.setState({ page: null })
                    }
                    const { request } = PageService.deleteBlock(blockId);
                    const response = await request();

                    if (response.status !== 204) {
                        throw new Error();
                    }
                    loadPage();
                } catch (Error) {
                    loadPage();
                }
            })();
        },
        [isMounted, loadPage],
    );

    const updatePositions = React.useCallback<(positions: TBlockPositionList) => void>(
        positions => {
            (async () => {
                try {
                    if (isMounted()) {
                        setIsSorting(true);
                    }
                    const { request } = PageService.updateBlocksPosition(positions);
                    const response = await request();

                    if (response.status !== 200) {
                        throw new Error();
                    }

                    if (isMounted()) {
                        setIsSorting(false);
                    }
                } catch (Error) {
                    setIsSorting(false);
                }
            })();
        },
        [isMounted],
    );

    const updatePage = React.useCallback<TOnUpdatePage>(
        (pageId, data) => {
            (async () => {
                try {
                    if (isMounted()) {
                        usePageStore.setState({ page: null })
                    }

                    const { request } = PageService.updatePage(pageId, data);
                    const response = await request();
                    if (response.status !== 200) {
                        throw new Error();
                    }
                    loadPage();
                } catch (Error) {
                    loadPage();
                }
            })();
        },
        [isMounted, loadPage],
    );

    const deleteLink = React.useCallback<(linkId: string) => void>(
        linkId => {
            (async () => {
                try {
                    if (isMounted()) {
                        setSocialLinks(null);
                    }
                    const { request } = SocialLinkService.deleteSocialLink(linkId);
                    const response = await request();
                    if (response.status !== 204) {
                        throw new Error();
                    }
                    loadPage();
                } catch (Error) {
                    loadPage();
                }
            })();
        },
        [isMounted, loadPage],
    );

    const addLink = React.useCallback<TOnAddLink>(
        (position: string, pageId: string, link: string) => {
            (async () => {
                try {
                    if (isMounted()) {
                        setSocialLinks(null);
                    }
                    const { request } = SocialLinkService.addSocialLink({
                        link_position: position,
                        pages_id: pageId,
                        social_link: link,
                    });
                    const response = await request();
                    if (response.status !== 201) {
                        throw new Error();
                    }
                    loadPage();
                } catch (Error) {
                    loadPage();
                }
            })();
        },
        [isMounted, loadPage],
    );

    const updateLinksPosition = React.useCallback<(list: TSocialLinkPositionList) => void>(
        list => {
            (async () => {
                try {
                    const { request } = SocialLinkService.updateSocialLinksPosition(list);
                    const response = await request();
                    if (response.status !== 200) {
                        throw new Error();
                    }
                    loadPage();
                } catch (Error) {
                    loadPage();
                }
            })();
        },
        [loadPage],
    );

    if (!publicPage) {
        return (
            <BlockContainer isTransparent>
                <Loader />
            </BlockContainer>
        );
    }

    if (isPreview && onEndPreview) {
        return (
            <PreviewPage
                page={publicPage}
                onClose={onEndPreview}
            />
        );
    }

    return (
        <>
            <DefaultBlock marginTop="0px" page={publicPage} onOpenEdit={openDefaultEdit} />
            {isTipVisible && blocks.length === 0 && (
                <TipBlock onClick={closeTip} />
            )}
            {blocks.length > 0 && (
                <SortableBlocks
                    isSaving={isSorting}
                    blocks={blocks}
                    onChangeOrder={setBlocks}
                    onEdit={selectBlock}
                    onSort={updatePositions}
                    color={publicPage.page_font_color || undefined}
                    fontFamily={publicPage.page_font || undefined}
                />
            )}
            <div className={c(styles.buttonContainer)}>
                <MainButton className={c(styles.mainButton)} onClick={openAddSimpleBlock}>{t('BTN_ADD_BLOCK')}</MainButton>
                <MainButton className={c(styles.mainButtonAdvanced)}>{t('ADD_ADV_BLOCK')}</MainButton>
            </div>
            {isAddSimpleVisible && (
                <AddSimpleBlock
                    onAdd={addBlock}
                    onUpdate={updateBlock}
                    onClose={onCloseManageBlock}
                    onDelete={deleteBlock}
                    block={selectedBlock}
                />
            )}
            {isDefaultEdit && (
                <EditDefaultBlock
                    page={publicPage}
                    socialLinks={socialLinks}
                    onDeleteLink={deleteLink}
                    onAddLink={addLink}
                    onSortLinks={updateLinksPosition}
                    onSubmit={updatePage}
                    onClose={closeDefaultEdit}
                />
            )}
        </>
    );
}

export const UserPage = React.memo(UserPage_);
