import React from "react";
import { useTranslation } from "react-i18next";

import { DefaultPageCover, TextColors } from "src/constants";
import { TTextStyle } from "src/types";
import { StandardButton } from "src/ui/buttons/StandardButton";
import { Txt } from "src/ui/text";
import styled from "styled-components";

type TProps = Readonly<{
    cover: string | null;
    coverColor: string | null;
    pageTagline?: string | null;
    minHeight?: string;
    onClosePreview?: () => void;
}>;

interface IPageCover {
    cover: string | null;
    coverColor: string | null;
    minHeight?: string;
}

const PageCover = styled.div<IPageCover>`
    width: 100%;
    min-height: ${({ minHeight }) => minHeight || '240px'};
    background-image: ${({ cover }) => !!cover ? `url(${cover})` : 'unset'};
    background-color: ${({ cover, coverColor }) => (!!coverColor && !cover) ? coverColor : 'unset'};
    border-radius:  12px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
`

interface ITaglineProps {
    top: string;
}

const Tagline = styled.div<ITaglineProps>`
    position: absolute;
    top: ${({ top }) => top || undefined};
    left: 20px;
    right: 20px;
`

const Head_: React.FC<TProps> = ({
    cover,
    coverColor,
    pageTagline,
    minHeight,
    onClosePreview
}: TProps) => {
    const { t } = useTranslation();

    const isPreview = !!onClosePreview;
    const isLongTagline: boolean = (pageTagline || "").length >= 129;

    const textStyle: TTextStyle = isLongTagline ?  "body_12" : "title_lg";

    const top = React.useMemo<string>(
        () => {
            if (isLongTagline) {
                return isPreview ? '62px' : '30px';
            }
            return isPreview ? '82px' : '50px';
        },
        [isLongTagline, isPreview]
    );

    const coverFinal = React.useMemo<string | null>(
        () => {
            if (!!cover) {
                return cover;
            }
            return !!coverColor ? null : DefaultPageCover;
        },
        [cover, coverColor]
    );

    return (
        <PageCover 
            cover={coverFinal} 
            coverColor={coverColor} 
            minHeight={minHeight} 
        >
            {!!pageTagline && (
                <Tagline top={top}>
                    <Txt textStyle={textStyle} color={TextColors.dark5}>
                        {pageTagline}
                    </Txt>
                </Tagline>
            )}
            {onClosePreview && (
                <StandardButton 
                    className="btn-secondary position-absolute top-0 start-0 mt-4 ms-4"
                    label={t('BTN_BACK_FROM_EDIT')}
                    labelStyle="uppercase"
                    labelColor={TextColors.dark1}
                    onClick={onClosePreview}
                />
            )}
        </PageCover>
    )
};

export const Head = React.memo(Head_);
