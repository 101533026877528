import React from "react";

import { useNavigate} from "react-router-dom";
import { DefaultBlock } from "src/components/blocks/DefaultBlock";
import { SimpleBlock } from "src/components/blocks/SimpleBlock";
import { useIsMounted } from "src/hooks/useIsMounted";
import { usePage } from "src/hooks/usePage";
import { usePageStore } from "src/store/usePage";
import { TPageBlockList, TPublicPage } from "src/types";
import { BlockContainer } from "src/ui/containers/BlockContainer";
import { Loader } from "src/ui/loader";
import { sortBlocks } from "src/utils/sort-blocks";
import styled from "styled-components";
import shallow from "zustand/shallow";

const BlocksList = styled.div`
    width: 100%;
    padding: 0 10px;
`

const PublicPage_: React.FC = () => {
    const {pageName} = usePage();
    const navigate = useNavigate();
    const isMounted = useIsMounted();

    const getPage = usePageStore((state) => state.getPage);

    const [publicPage, setPublicPage] = React.useState<TPublicPage | null>(null);
    const [blocks, setBlocks] = React.useState<TPageBlockList>([]);

    const onPageLoaded = React.useCallback<(selectedState: TPublicPage | null) => void>(
        (selectedState) => {
            if ((selectedState === null) || (pageName !== selectedState?.page_address)) {
                navigate('/signin');
                return;
            }

            if (isMounted()) {
                setPublicPage(selectedState);
                setBlocks(sortBlocks(selectedState.blocks)); 
            }
        },
        [isMounted, navigate, pageName],
    );

    React.useEffect(
        () => {
            if (!pageName) {
                return;
            }
            getPage(pageName);
        }, [getPage, pageName],
    );
    
    React.useEffect(() => {
        const unsubscribe = usePageStore.subscribe(
            (state) => state.page, 
            (curr, _) => onPageLoaded(curr), 
            { equalityFn: shallow }
        );
        return () => {
            unsubscribe();
            usePageStore.setState({ page: null })
        };
    }, [onPageLoaded]);

    if (!publicPage) {
        return (
          <BlockContainer isTransparent>
              <Loader />
          </BlockContainer>
        );
    }

    return (
        <>
            <DefaultBlock 
                isPublicPage 
                isTransparent
                page={publicPage} 
                padding="0 0 20px 0"
                blockBodyPadding="0 10px"
                coverHeight="287px"
                marginTop="0"
            />
            <BlocksList>
                {blocks.map((item) =>
                    <SimpleBlock 
                        isTransparent
                        key={item.block_id} 
                        item={item}
                        color={publicPage.page_font_color || undefined}
                        fontFamily={publicPage.page_font || undefined}
                    />
                )}
            </BlocksList>
        </>
    );
};

export const PublicPage = PublicPage_;
