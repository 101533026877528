import { TTextStyle } from "src/types";

export type TButtonStyle = 'sm' | 'lg' | 'auto';

export type TButtonOptions = Readonly<{
    width?: string;
    height?: string;
    textStyle?: TTextStyle;
    fontFamily?: string;
}>;

export const defaultOptions: TButtonOptions = {
    width: undefined,
    height: undefined,
    textStyle: undefined,
};

export const ButtonOptions: Readonly<Record<TButtonStyle, TButtonOptions>> = {
    sm: {
        height: '32px',
    },
    lg: {
        height: '51px',
        width: '100%',
        textStyle: 'h3',
    },
    auto: {
        height: undefined,
    },
};
