import React, { FC } from "react";

import styles from "./style.module.sass";
import { useTranslation } from "react-i18next";
import { TPage } from "src/types";
import { useNavigate } from "react-router-dom";
import { PagesHead } from "../PagesHead";
import styled from "styled-components";
import { BackgroundColors, ScreenSizes, TextColors } from "src/constants";
import { Txt } from "src/ui/text";

type TProps = Readonly<{
    userName: string;
    item: TPage;
}>;

const Button = styled.button`
    width: 100%; 
    margin-bottom: 20px; 
    border-radius: 12px;
    background-color: transparent;
`

const Container = styled.div`
    display: flex;
`
const RoleContainer = styled.div`
    margin-top: 5px;
    background-color: ${TextColors.dark3};
    border-radius: 4px;
    padding: 1px 6px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${BackgroundColors.bgWhite};
    height: 22px;
    width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const DescriptionContainer = styled.div`
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    text-align: start;
    width: calc(50% - 20px);
    @media (min-width: ${ScreenSizes.xsm}) {
        width: 165px;
    }
`

const Description = styled.div`
    color: ${TextColors.dark1};
    font-weight: 600;
    font-size: 14px;
    height: 24px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 24px;
`

const TextContainer = styled.div`
    margin-bottom: 15px;
`

const PageItem_: FC<TProps> = ({
    userName,
    item
}: TProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        page_cover, page_photo, page_name, page_title, 
        page_address, page_description, roles_id, page_cover_color
    } = item;
    const role: string = roles_id ? t(`ROLE_${roles_id}`) : '';

    const onClick = React.useCallback(() => {
        navigate(`${item.page_address}`);
      }, [item.page_address, navigate],
    );

    return (
        <Button onClick={onClick}>
            <Container>
                <PagesHead
                    className={styles.pageImgBox}
                    userName={userName}
                    cover={page_cover}
                    coverColor={page_cover_color}
                    pageName={page_name}
                    pagePhoto={page_photo}
                />
                <DescriptionContainer>
                    <TextContainer>
                        {!!page_title && (<Txt textStyle="h2" className="d-block text-truncate">{page_title}</Txt>)}
                        {!!page_address && (
                            <Txt textStyle="page_address" className="d-block text-truncate" color={TextColors.dark3}>
                                {page_address}
                            </Txt>
                        )}
                    </TextContainer>
                    <div>
                        {!!page_description && (<Description>{page_description}</Description>)}
                        {!!role && (<RoleContainer>{role}</RoleContainer>)}
                    </div>
                </DescriptionContainer>
            </Container>
        </Button>
    );
};

export const PageItem = React.memo(PageItem_);
