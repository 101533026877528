import React from "react";

import { BackgroundColors } from "src/constants";
import { Main } from "./Main";
import { Header } from "./Header";
import { Outlet } from "react-router-dom";
import { Wrapper } from "./Wrapper";

const AdminLayout_: React.FC = () => {
    return (
        <Main backgroundColor={BackgroundColors.bgExtraWhite}>
          <Header />
          <Wrapper padding='40px 20px'>
            <Outlet />
          </Wrapper>
        </Main>
    );
};

export const AdminLayout = React.memo(AdminLayout_);
