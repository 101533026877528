import React from "react";

import defaultImage from 'src/assets/img/imageUploadSm.svg';
import { useUploader } from "./useUploader";
import styled from "styled-components";
import closeImage from 'src/assets/img/closeImage.svg';
import addImage from 'src/assets/img/addImage.svg';

type TProps = Readonly<{
    url: string | null;
    imageFile: File | null;
    onSetImageFile: (val: File) => void;
    onRemove: () => void;
}>;

interface IImageProps {
    imgUrl: string;
    width?: string;
    borderRadius?: string;
}

const Image = styled.div<IImageProps>`
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: ${({ imgUrl }) => `url(${imgUrl})`};
    height: 160px;
`

const ControlsContainer = styled.div`
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
`

interface IIconProps {
    src: string;
}

const Icon = styled.button<IIconProps>`
    background-image: ${({ src }) => `url(${src})`};
    width: 32px;
    height: 32px;
    padding: 2px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-size: contain;
    margin: 8px;
`

const CoverUploader_: React.FC<TProps> = ({
    url,
    imageFile = null,
    onSetImageFile,
    onRemove,
}: TProps) => {
    const { fileInputRef, imageURL, forwardClickToInput, onImageChange } = useUploader({
        url, imageFile, defaultImage: defaultImage, onSetImageFile
    });

    return (
        <div className="mt-2 mb-4 position-relative">
            <input 
                hidden
                type="file" 
                accept="image/*" 
                ref={fileInputRef}
                onChange={onImageChange} 
            />
            <Image imgUrl={imageURL} className="rounded" />
            <ControlsContainer>
                <Icon src={closeImage} onClick={onRemove} />
                <Icon src={addImage} onClick={forwardClickToInput} />
            </ControlsContainer>
        </div>
    );
};

export const CoverUploader = React.memo(CoverUploader_);
