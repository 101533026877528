import React from "react";

import { BackgroundColors, ButtonColors, Colors, DefaultPageDescription, DefaultPageName, DefaultPagePhoto, DefaultPageTitle } from "src/constants";
import { Txt } from "src/ui/text";
import styled from "styled-components";
import { SocialIcons } from "./SocialIcons";
import { TPageSocialLinkList } from "src/types";

type TProps = Readonly<{
    userName: string;
    pageTitle: string;
    pageDescription: string;
    pagePhoto: string | null;
    pageName: string | null;
    socialLinks: TPageSocialLinkList;
    padding?: string;
}>;

interface ILowerBlockProps {
    padding?: string;
}

const LowerBlock = styled.div<ILowerBlockProps>`
    width: 100%;
    text-align: center;
    border-radius: 0px 0px 12px 12px;
    top: -67px;
    position: relative;
    padding: ${({ padding }) => padding || undefined};
`

const ImageContainer = styled.img`
    border-radius: 50%;
    bottom: -60px;
    left: 105px;
    width: 130px;
    height: 130px;
    border: 8px solid ${BackgroundColors.bgWhite};
`

const PageTitle = styled.div`
    background-color: ${Colors.sysBlue5};
    border-radius: 30px;
    padding: 5px 15px;
    min-height: 34px;
    display: inline-block;
    margin-top: 30px;
    word-break: break-word;
`
const PageDescription = styled.div`
    padding: 30px 0px;
`

const Body_: React.FC<TProps> = ({
    userName,
    pageTitle,
    pageDescription,
    pagePhoto,
    pageName,
    socialLinks,
    padding
}: TProps) => {
    return (
        <LowerBlock padding={padding}>
            <ImageContainer className={pagePhoto ? undefined : "bg-white"} src={pagePhoto || DefaultPagePhoto} alt="">
            </ImageContainer>
            <Txt textStyle="title_xlg" display="block" className="mt-6">{pageName || DefaultPageName}</Txt>
            <Txt textStyle="title_md" className="mt-2" wordBreak="break-word">{userName}</Txt>
            <PageTitle>
                <Txt textStyle="body_14" textAlign="center" color={ButtonColors.active}>
                    {pageTitle || DefaultPageTitle}
                </Txt>
            </PageTitle>
            <PageDescription>
                <Txt textAlign="center" textStyle="body_16">{pageDescription || DefaultPageDescription}</Txt>
            </PageDescription>
            {socialLinks && (
                <SocialIcons list={socialLinks} />
            )}
        </LowerBlock>
    )
};

export const Body = React.memo(Body_);
