import React from "react";

import { ImageSizes_350 } from "src/constants";
import { ImageType, TImgSize } from "src/types";
import styled from "styled-components";
import { ImageContainer } from "../ImageContainer";
import { TList } from "../types";

type TProps = Readonly<{
    imageOption: ImageType;
    items: TList;
    hasMargin?: boolean;
}>;

const getGap = (
    containerWidth: number, 
    numberInRow: number, 
    width: number, 
): number => {
    return (containerWidth - (width * numberInRow)) / (numberInRow - 1);
};

interface IContainer {
    marginTop: string | undefined;
}

const Container = styled.div<IContainer>`
    margin-top: ${({ marginTop }) => marginTop};
    display: flex;
    flex-wrap: wrap;
`

const SimpleList_: React.FC<TProps> = ({
    imageOption,
    items,
    hasMargin = true,
}: TProps) => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [containerWidth, setContainerWidth] = React.useState<number>();

    const sizeOption = React.useMemo<TImgSize>(
        () => {
            const standartSize = ImageSizes_350[imageOption];

            if (!containerWidth) {
                return standartSize;
            }
            const imgSize = (containerWidth * standartSize.size) / 350;

            return {
                size: imgSize,
                numberInRow: standartSize.numberInRow,
            };
        },
        [containerWidth, imageOption],
    );
    
    const gap = React.useMemo<number>(
        () => {
            if (sizeOption.numberInRow === 1 || !containerWidth) {
                return 10;
            }
           return getGap(containerWidth, sizeOption.numberInRow, sizeOption.size);
        },
        [containerWidth, sizeOption.numberInRow, sizeOption.size],
    );

    const handleWindowResize = React.useCallback(
        () => {
            if (!ref.current) {
                return;
            }
            setContainerWidth(ref.current.offsetWidth);
        },
        [],
    );

    React.useEffect(
        () => {
            handleWindowResize();
        },
        [handleWindowResize],
    );

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
      
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    return (
        <Container ref={ref} marginTop={hasMargin ? '30px' : undefined}>
            {items.map((item, index) => (
                <ImageContainer
                    {...sizeOption}
                    key={item.image.id} 
                    index={index} 
                    item={item.image} 
                    onImageRemove={item.onImageRemove} 
                    onClick={item.onForwardClick} 
                    gap={gap}
                />
            ))}
        </Container>
    );
};

export const SimpleList = React.memo(SimpleList_);
