import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { TPageList } from "src/types";
import { PageItem } from "./PageItem";
import styles from "./style.module.sass";

type TProps = Readonly<{
  userId: number;
  userName: string;
  list: TPageList;
}>;

const PagesBlock_: FC<TProps> = ({
  userId,
  userName,
  list
}: TProps) => {
  const { t } = useTranslation();
  const noPages: string = t('TITLE_NO_PAGES');

  if (list.length === 0 || userId < 0) {
      return (
        <div className={styles.pageAltText}>{noPages}</div>
      );
  }

  return (
    <>
      {list.map((item) =>
        <PageItem 
          key={item.id} 
          item={item} 
          userName={userName} 
        />
      )}
    </>
  );
};

export const PagesBlock = PagesBlock_;
