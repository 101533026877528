import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Txt } from "src/ui/text";
import { ScreenSizes, TextColors } from "src/constants";
import profileImage from 'src/assets/img/profile.svg';
import { StandardButton } from "src/ui/buttons/StandardButton";

type TContainerProps = Readonly<{
    width: number;
}>;

type TProps = TContainerProps & Readonly<{
    pageAddress: string;
    onClick: () => void;
}>;

const Address = styled.div`
    display: flex;
    align-items: center;
    width: calc(100% - 84px); // button size and padding
`

const Container = styled.div<TContainerProps>`
    width: ${({ width }) => `calc(${width}px - 40px)` || undefined};
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Icon = styled.div`
    background-image: url(${profileImage})};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: transparent;
    width: 16px;
    height: 20px;
    margin-right: 5px;
    @media (min-width: ${ScreenSizes.xsm}) {
        margin-right: 10px;
    }
`

const PagePreviewButton_: FC<TProps> = ({
    width,
    pageAddress,
    onClick
}: TProps) => {
    const { t } = useTranslation();

    return (
        <Container width={width} className="position-absolute top-50 start-50 translate-middle">
            <Address>
                <Icon />
                <Txt textStyle="h3" className="d-block text-truncate" color={TextColors.black1}>
                    ntmy.pro/{pageAddress}
                </Txt>
            </Address>
            <StandardButton 
                onClick={onClick}
                label={t('PREVIEW') || undefined}
                labelStyle="uppercase"
                width="79px"
                height="32px"
                className="btn-info"
            />
        </Container>
    );
};

export const PagePreviewButton = React.memo(PagePreviewButton_);
