import React from "react";

import { Main } from "../Main";
import { Header } from "../Header";
import { Wrapper } from "../Wrapper";
import { Outlet } from "react-router-dom";
import { 
    DefaultPrivatePageBgColor, DefaultPublicPageBgColor
} from "src/constants";
import { useFlag } from "src/hooks/useFlag";
import { useContainerDimensions } from "src/hooks/useContainerDimensions";
import { usePageState } from "../../hooks/usePageState";

type TProps = Readonly<{
    pageName: string;
}>;

const DefaultPrivatePagePadding = '0px 10px 40px';

const PrivatePageLayout_: React.FC<TProps> = ({
    pageName
}: TProps) => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const { width } = useContainerDimensions(ref);
    const {isLoaded, pageBackground} = usePageState(pageName);
    const [isPreview, openPreview, closePreview] = useFlag();

    const [previewBgColor, setPreviewBgColor] = React.useState<string>(DefaultPublicPageBgColor);
    const [pagePadding, setPagePadding] = React.useState<string>(DefaultPrivatePagePadding);
    
    const startPreviewMode = React.useCallback(
        () => {
            setPreviewBgColor(pageBackground || DefaultPublicPageBgColor);
            setPagePadding('0');
            openPreview();
        },
        [openPreview, pageBackground]
    );

    const endPreviewMode = React.useCallback(
        () => {
            setPreviewBgColor(pageBackground || DefaultPrivatePageBgColor);
            setPagePadding(DefaultPrivatePagePadding);
            closePreview();
        },
        [closePreview, pageBackground]
    );

    return (
        <Main backgroundColor={isPreview ? previewBgColor : (pageBackground || DefaultPrivatePageBgColor)}>
            <Header
                isSticky={isLoaded ? !isPreview : false}
                pageAddress={pageName}
                pageWidth={width}
                onClick={startPreviewMode}
            />
            <Wrapper wrapperRef={ref} padding={pagePadding}>
                <Outlet context={{
                    pageName,
                    isPreview,
                    onEndPreview: endPreviewMode,
                }}
                />
            </Wrapper>
        </Main>
    )
};

export const PrivatePageLayout = PrivatePageLayout_;
