import React from "react";

import { TRegisteredCard } from "src/types";
import { Slider } from "src/ui/slider/Slider";
import styled from "styled-components";
import { ScreenSizes } from "src/constants";
import { Card } from "../Card";


type TProps = Readonly<{
    list: ReadonlyArray<TRegisteredCard>;
    onSelectCard: (card: TRegisteredCard) => void;
}>;

const SliderContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  overflow-x: hidden;
  width: 100%;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.05));
  @media (min-width: ${ScreenSizes.xsm}) {
    width: 350px;
  }
`

const Cards_: React.FC<TProps> = ({
    list,
    onSelectCard
}: TProps) => {
    return (
      <SliderContainer>
          <Slider deviceType="mobile" itemsInRow={1}>
            {list.map((item) =>
                <Card 
                  key={item.key} 
                  gap={30}
                  item={item} 
                  onClick={onSelectCard} 
                />
            )}
        </Slider>  
      </SliderContainer>
    );
}

export const Cards = React.memo(Cards_);
