import React from "react";

import { ImageSizes_350 } from "src/constants";
import { ImageType } from "src/types";
import { Slider } from "src/ui/slider/Slider";
import { ImageContainer } from "../ImageContainer";
import { TList } from "../types";
import styles from "./style.module.sass";

type TProps = Readonly<{
    items: TList;
    hasMargin?: boolean;
}>;


const Carousel_: React.FC<TProps> = ({
    items,
    hasMargin = true
}: TProps) => {
    const ref = React.createRef<HTMLDivElement>();
    const [imageWidth, setImageWidth] = React.useState<number>();

    const standartSize = ImageSizes_350[ImageType.Carousel];

    const handleWindowResize = React.useCallback(
        () => {
            if (!ref.current) {
                return;
            }
            setImageWidth(ref.current.offsetWidth);
        },
        [ref],
    );

    React.useEffect(
        () => {
            handleWindowResize();
        },
        [handleWindowResize],
    );

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
      
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    return (
            <Slider 
                isSSr
                isCenterMode
                deviceType="mobile"
                itemsInRow={standartSize.numberInRow}
                containerClass={hasMargin ? styles.sliderContainer : undefined}
                itemClass={styles.sliderItem}
            >
                {items.map((item, index) => (
                    <ImageContainer
                        isCarousel
                        imageRef={ref}
                        size={imageWidth ?? standartSize.size}
                        numberInRow={items.length}
                        key={item.image.id} 
                        index={index} 
                        item={item.image} 
                        onImageRemove={item.onImageRemove} 
                        onClick={item.onForwardClick}
                        gap={10}
                    />
                ))}
            </Slider>
    );
};

export const Carousel = React.memo(Carousel_);
