import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

type TProps = Readonly<{
    children: React.ReactNode;
    className?: string;
    itemClass?: string;
    sliderClass?: string;
    containerClass?: string;
    isCenterMode?: boolean;
    deviceType?: "desktop" | "tablet" | "mobile";
    isSSr?: boolean;
    itemsInRow?: number;
}>;
  
const defaultItemsNumber: number = 2;

const Slider_: React.FC<TProps> = ({
    children,
    className,
    itemClass,
    sliderClass,
    containerClass,
    isCenterMode = false,
    deviceType,
    isSSr,
    itemsInRow,
}: TProps) => {
    return (
        <Carousel 
        swipeable 
        centerMode={isCenterMode}
        showDots={false} 
        arrows={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: itemsInRow ?? defaultItemsNumber,
            partialVisibilityGutter: 0
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: itemsInRow ?? defaultItemsNumber,
            partialVisibilityGutter: 0
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: itemsInRow ?? defaultItemsNumber,
            partialVisibilityGutter: 0
          }
        }}
        className={className}
        itemClass={itemClass}
        sliderClass={sliderClass}
        containerClass={containerClass}
        deviceType={deviceType}
        ssr={isSSr}
        partialVisible={!isCenterMode}
      >
        {children}
      </Carousel>
    )

}

export const Slider = React.memo(Slider_);
