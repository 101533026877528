import React from "react";

import { BackgroundColors, ButtonColors, Colors, PageDefaultFont, TextColors } from "src/constants";
import { Txt } from "src/ui/text";
import styled from "styled-components";
import { ButtonOptions, TButtonStyle, defaultOptions } from "./constants";
import { Loader } from "src/ui/loader";
import { TTextStyle } from "src/types";

type TButtonInnerProps = Readonly<{
    margin?: string;
    isDisabled?: boolean;
    width?: string;
    height?: string;
    color?: string;
    hoverColor?: string;
    padding?: string;
}>;

type TProps = TButtonInnerProps & Readonly<{
    onClick: () => void;
    label?: string;
    buttonStyle?: TButtonStyle;
    isLoading?: boolean;
    spinnerColor?: string;
    labelColor?: string;
    labelStyle?: TTextStyle;
    fontFamily?: string;
}>;

const ButtonInner = styled.button<TButtonInnerProps>`
    background-color: ${({ isDisabled, color }) => isDisabled 
        ? ButtonColors.disabled 
        : (color || Colors.sysBlue1)};
    cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'unset'};
    height: ${({ height }) => height || undefined};
    width: ${({ width }) => width || undefined};
    padding: ${({ padding }) => padding || '6px 12px'};
    border-radius: 12px;
    display: block;
    margin: ${({ margin }) => typeof margin === 'string' ? margin : 'unset'};
    transition: 0.3s ease background-color;
    &:hover {
        background-color: ${({ isDisabled, hoverColor }) => isDisabled 
            ? ButtonColors.disabled 
            : (hoverColor || ButtonColors.active)};
    };
`

const Button_: React.FC<TProps> = ({
    margin,
    onClick,
    label,
    isDisabled = false,
    buttonStyle,
    isLoading = false,
    spinnerColor,
    labelColor,
    color,
    hoverColor,
    labelStyle,
    fontFamily,
    width,
    height,
    padding
}: TProps) => {
    const opts = buttonStyle ? ButtonOptions[buttonStyle] : defaultOptions;

    return (
        <ButtonInner 
            {...opts}
            isDisabled={isDisabled} 
            margin={margin} 
            color={color}
            hoverColor={hoverColor}
            width={width || opts.width}
            height={height || opts.height}
            padding={padding}
            onClick={isDisabled ? undefined : onClick}
        >
            {isLoading 
            ? (<Loader noLabel color={spinnerColor} />) 
            : (!!label && <Txt 
                textStyle={labelStyle || opts.textStyle} 
                color={isDisabled ? TextColors.dark4 : (labelColor || BackgroundColors.bgWhite)}
                fontFamily={fontFamily || PageDefaultFont}
            >
                {label}
            </Txt>)}
        </ButtonInner>
    );
};

export const Button = React.memo(Button_);
