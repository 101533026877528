import React from "react";

type TOnEventCb = (e: React.ChangeEvent<HTMLInputElement>) => void;

type TIn = Readonly<{
    url: string | null;
    imageFile: File | null;
    defaultImage: string;
    onSetImageFile: (val: File) => void;
}>;

type TOut = Readonly<{
    fileInputRef: React.RefObject<HTMLInputElement>;
    imageURL: string;
    forwardClickToInput: () => void;
    onImageChange: TOnEventCb;
}>;

export const useUploader = ({url, imageFile, defaultImage, onSetImageFile}: TIn): TOut => {
    const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();

    const imageURL = React.useMemo<string>(
        () => {
            if (imageFile) {
                return URL.createObjectURL(imageFile);
            }
            if (url) {
                return url;
            }

            return defaultImage;
        },
        [defaultImage, imageFile, url],
    );

    const forwardClickToInput = React.useCallback(
        () => {
            fileInputRef.current!.click();
        },
        [fileInputRef],
    );

    const onImageChange = React.useCallback<TOnEventCb>(
        e => {
            const files: FileList | null = e.target.files;

            if(!files) {
                return;
            }
            onSetImageFile(files[0]);
        },
        [onSetImageFile],
    );

    return { fileInputRef, imageURL, forwardClickToInput, onImageChange };
}
