import React from "react";

import imgBg from 'src/assets/img/imageUploadSm.svg';
import { ImageType, TImageItem, TImageList } from "src/types";
import { Carousel } from "./Carousel";
import { SimpleList } from "./SimpleList";
import { TList } from "./types";

type TProps = Readonly<{
    imageOption: ImageType;
    items: TImageList;
    hasMargin?: boolean;
    onForwardClick?: () => void;
    onRemove?: (id: string) => void;
    carouselOnOver?: () => void;
    carouselOnLeave?: () => void;
}>;

const LoaderItem: TImageItem = {
    id: '-1',
    block_image_url: imgBg,
};

const ImageList_: React.FC<TProps> = ({
    imageOption,
    items,
    hasMargin,
    onForwardClick,
    onRemove,
    carouselOnOver,
    carouselOnLeave,
}: TProps) => {
    const list = React.useMemo<TList>(
        () => {
            const images = items.map(x => ({
                image: x,
                onImageRemove: onRemove,
            }));

            if (onForwardClick) {
                return [...images, {
                    image: LoaderItem,
                    onForwardClick: onForwardClick,
                }];
            }
            return images;
        },
        [items, onForwardClick, onRemove],
    );

    if (imageOption === ImageType.Carousel) {
        return (
            <div onPointerOver={carouselOnOver} onPointerLeave={carouselOnLeave}>
                <Carousel items={list} hasMargin={hasMargin} />
            </div>
        );
    }
    return (
        <SimpleList 
            imageOption={imageOption}
            items={list}
            hasMargin={hasMargin}
        />
    );
};

export const ImageList = React.memo(ImageList_);
