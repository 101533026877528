import { 
    CardType, ImageType, TImgSize, TRegisteredCard
} from "./types";
import defaultCoverImg from "src/assets/img/default-page-cover.svg";
import defaultPhotoImg from "src/assets/img/default-page-photo.svg";

export const Colors = {
    sysBlue1: '#3F8CFF',
    sysBlue2: '#65A3FF',
    sysBlue3: '#8CBAFF',
    sysBlue4: '#C5DDFF',
    sysBlue5: '#ECF4FF',
    sysBlue6: '#597393',
};

export const BackgroundColors = {
    bgWhite: '#FFFFFF',
    bgExtraWhite: '#FDFDFD',
    bgDarkWhite: '#F3F3F8'
};

export const ButtonColors = {
    disabled: '#F8F8FA',
    active: '#1271FF',
    secondaryActive: '#E2E3E4',
}

export const ScreenSizes = {
    xsm: '425px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px'
};

export const TabletScreenWidth: number = 690;

export const PageDefaultFont: string = "'Inter', sans-serif";

export const StatusColors = {
    dangerous: '#FF0000',
    bad: '#FF6B00',
    good: '#15AA2C'
};

export const TextColors = {
    dark1: '#04021D',
    dark2: '#36354A',
    dark3: '#686777',
    dark4: '#D2D2D7',
    dark5: '#1E3240',
    black1: '#000000',
    black2: '#2E3B4C', // only used for loader
};

export const UserColors = {
    darkBlue: '#051B56',
    pink: '#F9DAD9',
    lightBlue: '#B8D9FB',
    orange: '#ED6E43',
    black: '#000000',
    pinkPurple: '#D2A9F9',
    green: '#326C50',
    cinnabar: '#E84A37',
    blue: '#485DD3',
};

export const ImageSizes_350: Readonly<Record<ImageType, TImgSize>> = {
    [ImageType.NoImage]: {size: 0, numberInRow: 0},
    [ImageType.Small]: {size: 110, numberInRow: 3},
    [ImageType.Medium]: {size: 170, numberInRow: 2},
    [ImageType.Big]: {size: 350, numberInRow: 1},
    [ImageType.Carousel]: {size: 150, numberInRow: 2},
};

export const DefaultReleasedCard: TRegisteredCard = {
    key: "",
    card_type: CardType.Physical,
    _links: {
      self: {
        href: ""
      }
    }
};

// pages
export const DefaultPrivatePageBgColor = BackgroundColors.bgDarkWhite;

export const DefaultPublicPageBgColor = BackgroundColors.bgExtraWhite;

export const DefaultPageCover = defaultCoverImg;

export const DefaultPagePhoto = defaultPhotoImg;

export const DefaultPageName = "Your Name";

export const DefaultPageTitle = "Your Occupation";

export const DefaultPageDescription = [
    "We\u2019ve created the new video wich describe how our product works.", 
    "Hey, I\u2019m Jonny!"
].join(' ');
