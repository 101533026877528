import React from "react";

import { BackgroundColors, TextColors } from "src/constants";
import { CardType, TRegisteredCard } from "src/types";
import { Txt } from "src/ui/text";
import styled from "styled-components";
import NTMYImage from 'src/assets/img/NTMY.svg';
import NTMYpro from 'src/assets/img/NTMYpro.svg';
import soundImage from 'src/assets/img/sound.svg';

type TProps = Readonly<{
    gap: number;
    item: TRegisteredCard;
    onClick?: (card: TRegisteredCard) => void;
}>;

interface IContainerProps {
    backgroundColor: string;
    gap: number;
};

const Container = styled.button<IContainerProps>`
    width: ${({ gap }) => `calc(100% - ${gap}px)`};
    height: 212px;
    border-radius: 20px;
    background-color: ${({ backgroundColor }) => backgroundColor};
`
const NTMYIcon = styled.div`
    background-image: url(${NTMYImage})};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: transparent;
    position: absolute;
    width: 64px;
    height: 84px;
    left: 25px;
    top: 25px;
`

interface INTMYProIconProps {
    right: string;
};

const NTMYProIcon = styled.div<INTMYProIconProps>`
    background-image: url(${NTMYpro})};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: transparent;
    position: absolute;
    width: 41px;
    height: 9px;
    right: ${({ right }) => right};
    top: 25px;
`

const SoundIcon = styled.div`
    background-image: url(${soundImage})};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: transparent;
    position: absolute;
    width: 23px;
    height: 19px;
    left: 25px;
    bottom: 25px;
`

const Card_: React.FC<TProps> = ({
    gap,
    item,
    onClick,
}: TProps) => {
    const backgroundColor: string = item.card_type === CardType.Physical 
        ? BackgroundColors.bgWhite 
        : "#C6C2FF";

    const click = React.useCallback(
        () => {
            if (!onClick) {
                return;
            }
            onClick(item);
        },
        [item, onClick],
    );
    
    return (
        <Container gap={gap} backgroundColor={backgroundColor} onClick={click}>
            <NTMYIcon />
            <Txt color={TextColors.dark2} textStyle="h3" position="absolute" right={`${gap + 25}px`} bottom="25px">
                {item.key}
            </Txt>
            <NTMYProIcon right={`${gap + 25}px`} />
            <SoundIcon />
        </Container>
    );
};

export const Card = Card_;
