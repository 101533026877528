import React, { FC } from "react";

import styles from "./style.module.sass";
import c from "classnames";
import { Txt } from "src/ui/text";
import styled from "styled-components";
import { BackgroundColors } from "src/constants";

type TProps = Readonly<{
    userName: string;
    cover: string | null;
    coverColor: string | null;
    pageName: string | null;
    pagePhoto: string | null;
    pageTagline?: string | null;
    pageTitle?: string | null;
    className?: string;
    size?: 'sm' | 'lg';
    children?: React.ReactNode;
}>;

interface IPageCover {
    isSmall: boolean;
    cover: string | null;
    coverColor: string | null;
}

const PageCover = styled.div<IPageCover>`
    width: 100%;
    height: ${({ isSmall }) => isSmall ? '50%' : 'unset'};
    min-height: ${({ isSmall }) => isSmall ? 'unset' : '240px'};
    background-image: ${({ cover }) => !!cover ? `url(${cover})` : 'unset'};
    background-color: ${({ cover, coverColor }) => (!!coverColor && !cover) ? coverColor : 'unset'};
    border-radius: 12px 12px 4px 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
`
interface ILowerBlock {
    isSmall: boolean;
}

const LowerBlock = styled.div<ILowerBlock>`
    width: 100%;
    text-align: center;
    border-radius: 0px 0px 12px 12px;
    top: ${({ isSmall }) => isSmall ? '-45px' : '-67px'};
    position: relative;
`

const ImageContainer = styled.img`
    border-radius: 50%;
    left: 48px;
    bottom: -60px;
    width: 70px;
    height: 70px;
    border: 4px solid ${BackgroundColors.bgWhite};
`
const PagesHead_: FC<TProps> = ({
    userName,
    cover,
    coverColor,
    pageName,
    pagePhoto,
    pageTagline,
    className,
    size = 'sm',
    children,
}: TProps) => {
    const isSmall = size === 'sm';
    return (
        <div className={c(className)}>
            <PageCover isSmall={isSmall} cover={cover} coverColor={coverColor}></PageCover>
            <LowerBlock isSmall={isSmall}>
                {pagePhoto ? (
                    <ImageContainer src={pagePhoto} alt=""></ImageContainer>
                ) : (
                    <div className={c(size === 'sm' ? styles.pagePhotoSm : styles.pagePhotoLg)}></div>
                )}
                <div className="p-1">
                    <Txt textStyle="body_semibold" className="d-block text-truncate" minHeight="24px">{pageName}</Txt>
                    <Txt textStyle="help_text" className="d-block text-truncate">{userName}</Txt>
                </div>
                {children}
            </LowerBlock>
        </div>
    );
};

export const PagesHead = React.memo(PagesHead_);
