import React from "react";

import { BackgroundColors } from "src/constants";
import styled from "styled-components";
import { Txt } from "src/ui/text";
import { TIconList } from "src/types";
import { IconItem } from "./IconItem";

type TContainerInnerProps = Readonly<{
    isTextCentered?: boolean;
    padding?: string;
    isTransparent?: boolean;
    color?: string;
    fontFamily?: string;
    marginTop?: string;
}>;

type TProps = TContainerInnerProps & Readonly<{
    children: React.ReactNode;
    header?: string;
    icons?: TIconList;
    isClosed?: boolean;
    className?: string;
}>;

const ContainerInner = styled.div<TContainerInnerProps>`
    font-family: ${({ fontFamily }) =>  fontFamily || undefined};
    border-radius: 20px;
    background-color: ${({ isTransparent }) => isTransparent ? 'transparent' : BackgroundColors.bgWhite};
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: ${({ marginTop }) => marginTop || '20px'};
    padding: ${({ padding }) => typeof padding === 'string' ? padding : '45px 10px 20px'};
    text-align: ${({ isTextCentered }) => isTextCentered ? 'center' : 'unset'};
    color: ${({ color }) => color || 'unset'};
`

interface IIconContainerProps {
    justifyContent: string;
}

const IconContainer = styled.div<IIconContainerProps>`
   justify-content: ${({ justifyContent }) => justifyContent};
   display: flex;
   position: absolute;
   width: calc(100% - 20px);
   top: 17px;
   padding: 0 5px;
`
const BlockContainer_: React.FC<TProps> = ({
    children,
    header,
    isTextCentered = false,
    padding,
    icons = [],
    isTransparent = false,
    color,
    marginTop,
    fontFamily,
    isClosed = false,
    className
}: TProps) => {
    return (
        <ContainerInner 
            isTransparent={isTransparent} 
            isTextCentered={isTextCentered} 
            padding={padding} 
            color={color}
            marginTop={marginTop}
            fontFamily={fontFamily}
            className={className}
        >
            {icons.length > 0 && (
                <IconContainer justifyContent={icons.length === 1 ? 'flex-end' : 'space-between'}>
                    {icons.map((item, index) =>
                        <IconItem key={index} {...item} />
                    )}
                </IconContainer>
            )}
            {!isClosed && (
                <>
                    {!!header && (<Txt textStyle='h1'>{header}</Txt>)}
                    {children}
                </>
            )}
        </ContainerInner>
    );
};

export const BlockContainer = React.memo(BlockContainer_);
