import React from "react";

import Button from "react-bootstrap/esm/Button";
import { BackgroundColors, TextColors } from "src/constants";
import { TTextStyle } from "src/types";
import { Txt } from "src/ui/text";
import styled from "styled-components";

type TButtonInnerProps = Readonly<{
    width?: string;
    height?: string;
}>;

type TProps = TButtonInnerProps & Readonly<{
    onClick: () => void;
    isDisabled?: boolean;
    label?: string | null;
    labelColor?: string;
    className?: string;
    labelStyle?: TTextStyle;
}>;

const ButtonInner = styled(Button)`
    height: ${({ height }) => height || undefined};
    width: ${({ width }) => width || undefined};
`

const StandardButton_: React.FC<TProps> = ({
    onClick,
    isDisabled = false,
    label,
    height,
    width,
    labelColor,
    className,
    labelStyle
}: TProps) => {
    return (
        <ButtonInner
            className={className}
            disabled={isDisabled}
            onClick={isDisabled ? undefined : onClick} 
            height={height}
            width={width}
        >
            {!!label && (
                <Txt 
                    textStyle={labelStyle}
                    color={isDisabled ? TextColors.dark4 : (labelColor || BackgroundColors.bgWhite)}
                >
                    {label}
                </Txt>
            )}
        </ButtonInner>
    );
};

export const StandardButton = React.memo(StandardButton_);
