import React from "react";

import { Main } from "../Main";
import { Header } from "../Header";
import { Wrapper } from "../Wrapper";
import { Outlet } from "react-router-dom";
import { DefaultPublicPageBgColor } from "src/constants";
import { usePageState } from "../../hooks/usePageState";

type TProps = Readonly<{
  pageName: string;
}>;

const PublicPageLayout_: React.FC<TProps> = ({
  pageName
}: TProps) => {
  const {pageBackground} = usePageState(pageName);

  return (
    <Main backgroundColor={pageBackground || DefaultPublicPageBgColor}>
      <Header />
      <Wrapper padding="0">
        <Outlet context={{ pageName }} />
      </Wrapper>
    </Main>
  )
};

export const PublicPageLayout = PublicPageLayout_;
