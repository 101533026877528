import { APIClient } from "../APIClient";
import { TManageBlockRequest } from "../models/ManageBlockRequest";
import { TManageBlockResponse } from "../models/ManageBlockResponse";
import { TAddImageToBlockRequest } from "../models/AddImageToBlockRequest";
import { TAddImageToBlockResponse } from "../models/AddImageToBlockResponse";
import { TGetPublicPageResponse } from "../models/GetPublicPageResponse";
import { getAccessToken } from "./utils/get-access-token";
import { TBlockPositionList } from "src/types";
import { TUpdatePageRequest } from "../models/UpdatePageRequest";

export namespace PageService {
    export function addBlock(model: TManageBlockRequest) {
        const token: string = getAccessToken();
    
        return APIClient.request<
          TManageBlockRequest,
          TManageBlockResponse
      >({
          method: "POST",
          url: "/cm/pages_block",
          data: model,
          headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
      });
    };

    export function getPublicPage(pageName: string) {
        return APIClient.request<
            {},
            TGetPublicPageResponse
        >({
            method: "GET",
            url: `/pages/${pageName}`,
            headers: {
                "Content-Type": "application/problem+json",
                "Accept": "application/json",
            },
        });
    };

    export function addImageToBlock(model: TAddImageToBlockRequest) {
        const token: string = getAccessToken();
    
        return APIClient.request<
          TAddImageToBlockRequest,
          TAddImageToBlockResponse
      >({
          method: "POST",
          url: "/cm/pages_block_image",
          data: model,
          headers: {
            "Content-Type": "multipart/form-data; boundary=WebAppBoundary",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`,
        },
      });
    };

    export function deleteBlock(blockId: string) {
        const token: string = getAccessToken();

        return APIClient.request<
            {},
            {}
        >({
            method: "DELETE",
            url: `/cm/pages_block/${blockId}`,
            headers: {
                "Content-Type": "application/problem+json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
    };

    export function updateBlock(blockId: string, model: TManageBlockRequest) {
        const token: string = getAccessToken();
        return APIClient.request<
        TManageBlockRequest,
        any
      >({
        method: "PUT",
        url: `/cm/pages_block/${blockId}`,
        data: model,
        headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
      });
    };

    export function deleteBlockImage(imageId: string) {
        const token: string = getAccessToken();

        return APIClient.request<
            {},
            {}
        >({
            method: "DELETE",
            url: `/cm/pages_block_image/${imageId}`,
            headers: {
                "Content-Type": "application/problem+json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
    };

    export function updateBlocksPosition(model: TBlockPositionList) {
        const token: string = getAccessToken();
    
        return APIClient.request<
          TBlockPositionList,
          any
      >({
          method: "PATCH",
          url: "/cm/pages_block",
          data: model,
          headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
      });
    };

    export function updatePage(pageId: string, model: TUpdatePageRequest) {
        const token: string = getAccessToken();
        return APIClient.request<
        TUpdatePageRequest,
        any
      >({
        method: "PUT",
        url: `/cm/pages/${pageId}`,
        data: model,
        headers: {
            "Content-Type": "multipart/form-data; boundary=WebAppBoundary",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
      });
    };
};
