import React from "react";

import { Modal } from "react-bootstrap";
import { DefaultBlock } from "src/components/blocks/DefaultBlock";
import { SimpleBlock } from "src/components/blocks/SimpleBlock";
import { TPageBlockList, TPublicPage } from "src/types";
import { sortBlocks } from "src/utils/sort-blocks";
import styled from "styled-components";
import styles from "./style.module.sass";
import c from "classnames";


type TProps = Readonly<{
    page: TPublicPage;
    onClose: () => void;
}>;

const BlocksList = styled.div`
    width: 100%;
    padding: 0 10px;
`

const PreviewPage_: React.FC<TProps> = ({
    page,
    onClose
}: TProps) => {
    const blocks = React.useMemo<TPageBlockList>(
        () => sortBlocks(page.blocks),
        [page.blocks],
    );

    return (
        <Modal
            show 
            fullscreen 
            contentClassName={styles.previewModal} 
            dialogClassName={styles.modalDialog}
            backdrop={false}
            animation={false}
            className="p-0"
        >
            <Modal.Body className="p-0">
                <div className={c(styles.previewModalBody, 'm-auto')}>
                    <DefaultBlock 
                        isPublicPage 
                        isTransparent
                        page={page} 
                        padding="0 0 20px 0"
                        blockBodyPadding="0 20px"
                        coverHeight="287px"
                        marginTop="0"
                        onClosePreview={onClose}
                    />
                    <BlocksList>
                        {blocks.map((item) =>
                            <SimpleBlock 
                                isTransparent
                                key={item.block_id} 
                                item={item}
                                color={page.page_font_color || undefined}
                                fontFamily={page.page_font || undefined}
                            />
                        )}
                    </BlocksList>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export const PreviewPage = React.memo(PreviewPage_);
