import create from "zustand";
import { persist } from "zustand/middleware";

export type TokenType = "Bearer";

export interface AuthStore {
  tokenType: TokenType | null,
  accessToken: string | null,
  refreshToken: string | null,
  token: () => string | null,
  isAuthorized: () => boolean,
  setTokenType: (value: TokenType) => void,
  setAccessToken: (value: string) => void,
  setRefreshToken: (value: string) => void,
  clear: () => void
};

export const useAuth = create(persist<AuthStore>((set, get) => ({
  tokenType: null,
  accessToken: null,
  refreshToken: null,

  token: () => {
    const state = get();

    const tokenType = state.tokenType;
    const accessToken = state.accessToken;

    if (tokenType === null) return null;
    if (accessToken === null) return null;

    return `${tokenType} ${accessToken}`;
  },

  isAuthorized: () => {
    const state = get();

    const tokenType = state.tokenType;
    const accessToken = state.accessToken;
    const refreshToken = state.refreshToken;

    if (tokenType === null) return false;
    if (accessToken === null) return false;
    if (refreshToken === null) return false;

    return true;
  },

  setTokenType: (value) => set((_) => {
    return { tokenType: value };
  }),

  setAccessToken: (value) => set((_) => {
    return { accessToken: value };
  }),

  setRefreshToken: (value) => set((_) => {
    return { refreshToken: value };
  }),

  clear: () => set((_) => {
    return {
      tokenType: null,
      accessToken: null,
      refreshToken: null
    };
  })
}), {
  name: "auth",
  getStorage: () => window.sessionStorage
}));
