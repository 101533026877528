import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { Colors, TextColors } from "src/constants";
import { Txt } from "../text";

const _loaderItemsArray = Array.from(Array(12).keys());

type TContainerPropsInner = Readonly<{
  marginTop?: number;
  marginBottom?: number;
  width?: string;
}>;

type TSpinnerItemProps = Readonly<{
  color?: string;
}>;

type TProps = TSpinnerItemProps & TContainerPropsInner & Readonly<{
  label?: string;
  noLabel?: boolean;
}>;

const Container = styled.div<TContainerPropsInner>`
    margin-top: ${({ marginTop }) => typeof marginTop === 'number' ? `${marginTop}px` : undefined};
    margin-bottom: ${({ marginBottom }) => typeof marginBottom === 'number' ? `${marginBottom}px` : undefined};
    justify-content: center; 
    align-items: center;
    display: flex;
    flex-direction: column;
    width: ${({ width }) => typeof width === 'string' ? width : '100%'};
`
const Spinner = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
`

const spinner = keyframes`
    17% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
`;

const SpinnerItem = styled.div<TSpinnerItemProps>`
    transform-origin: 12px 12px;
    animation: ${spinner} 1.2s linear infinite;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 0px;
      left: 11px;
      width: 2px;
      height: 5px;
      border-radius: 20%;
      background: ${({ color }) => color || Colors.sysBlue6};
    };
    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    };
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    };
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    };
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    };
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    };
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    };
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    };
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    };
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    };
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    };
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    };
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    };
`

const Loader_: React.FC<TProps> = ({
  marginTop,
  marginBottom,
  noLabel = false,
  label,
  color,
  width,
}: TProps) => {
    const { t } = useTranslation();
    const labelFinal = label || `${t('STATUS_LOADING')}...`;

      return (
        <Container id="loader" marginTop={marginTop} marginBottom={marginBottom} width={width}>
            <Spinner>
              {_loaderItemsArray.map((value) => (
                <SpinnerItem color={color} key={value} />
              ))}
            </Spinner>
            {!noLabel && (
              <Txt textStyle="h3" color={color || TextColors.black2} marginTop="15px">
                {labelFinal}
              </Txt>
            )}
        </Container>
      );
};

  export const Loader = Loader_;
