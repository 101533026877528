import create from "zustand";
import { persist } from "zustand/middleware";
import { TRegisteredCard, TUserInfo } from "src/types";

type TProfileStore = Readonly<{
    selectedCard: TRegisteredCard | null;
    user: TUserInfo | null;
    selectUser: (value: TUserInfo) => void;
    selectCard: (value: TRegisteredCard | null) => void;
}>;

export const useProfileStore = create(persist<TProfileStore>((set, get) => ({
    user: null,
    selectedCard: null,
    selectUser: (value: TUserInfo) => set((_) => {
      return { user: value };
    }),
    selectCard: (value: TRegisteredCard | null) => set((_) => {
      return { selectedCard: value };
    }),
  }), {
    name: "profile",
    getStorage: () => window.sessionStorage
}));

  