import React from "react";

type TOut = Readonly<{
    width: number;
    height: number;
}>;

export const useContainerDimensions = (customRef: React.MutableRefObject<any | null>): TOut => {
    const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })
  
    React.useEffect(() => {
      const getDimensions = () => ({
        width: customRef.current.offsetWidth,
        height: customRef.current.offsetHeight
      })
  
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      if (customRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [customRef])
  
    return dimensions;
  };
