import { FC } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "src/store/useAuth";

const PrivateRoute: FC = ({ children }) => {
  const isAuthorized = useAuth((state) => state.isAuthorized);

  const _isAuthorized = (() => {
    return isAuthorized();
  })();

  return (
    <>
      { _isAuthorized
        ? children
        : <Navigate to="/signin" />
      }
    </>
  );
};
export default PrivateRoute;
