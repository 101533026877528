import React from "react";

import { useParams } from "react-router-dom";
import { AdminLayout } from "./components/AdminLayout";
import { PageLayoutInner } from "./components/PageLayoutInner";

const PageLayout_: React.FC = () => {
    const {pagename} = useParams();
    const isPage = typeof pagename === 'string';

    if (isPage) {
      return (
        <PageLayoutInner pageName={pagename} />
      );
    }

    return (
      <AdminLayout />
    );
};

export const PageLayout = React.memo(PageLayout_);
