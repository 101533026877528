import { FC } from "react";
import { Routes, Route } from "react-router-dom";

import Page404 from "src/pages/Page404";
import PageIndex from "src/pages/Index";
import PageSignIn from "src/pages/SignIn";
import PageSignUp from "src/pages/SignUp";
import { PageAdminHome } from "src/pages/AdminHome";

import AuthLayout from "src/components/layouts/AuthLayout";
import { PageLayout } from "src/components/layouts/PageLayout";

import PrivateRoute from "./PrivateRoute";
import {CardRoute} from "./CardRoute";
import { UserPage } from "src/pages/UserPage";
import { PublicPage } from "src/pages/PublicPage";
import { ActivationPage } from "src/pages/ActivationPage";
import { useScrollRestoration } from "./useScrollRestoration";

const Router: FC = () => {
  useScrollRestoration();
  
  return (
    <Routes>
      <Route index path="/" element={<PageIndex />} />
      <Route index path="/activation/:hashcode" element={<ActivationPage />} />
      <Route index path="/card/:id" element={<CardRoute />} />
      <Route element={<PageLayout />}>
        <Route index path="/:pagename" element={<PublicPage />} />
      </Route>
      <Route element={<AuthLayout />}>
        <Route path="/signin" element={<PageSignIn />} />
        <Route path="/signup" element={<PageSignUp />} />
      </Route>
      <Route path="/admin" element={
        <PrivateRoute>
          <PageLayout />
        </PrivateRoute>
      }>
        <Route index element={<PageAdminHome />} />
        <Route path=":pagename" element={<UserPage />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
export default Router;
