import React, { FC } from "react";

import "react-multi-carousel/lib/styles.css";
import { TSliderList } from "src/types";
import { Slider } from "../Slider";
import { SliderButtonItem } from "./SliderButtonItem";


type TProps = Readonly<{
  list: TSliderList;
  onClick: (value: string) => void;
  className?: string;
  activeValue: string;
}>;

const SliderButtons_: FC<TProps> = ({
  list,
  onClick,
  className,
  activeValue,
}: TProps) => {
    return (
        <Slider 
          containerClass={className} 
          deviceType="mobile" 
          itemsInRow={3}
        >
          {list.map((item) =>
              <SliderButtonItem 
                {...item} 
                key={item.value}
                isActive={item.value === activeValue} 
                onClick={onClick}
              />
          )}
        </Slider>  
    );
};

export const SliderButtons = React.memo(SliderButtons_);
