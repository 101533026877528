import React, { FC } from "react";

import { ImageType, TBlockImageList, TImageList } from "src/types";
import { ImageList } from "src/ui/images/ImageList";

type TProps = Readonly<{
    imageOption: ImageType;
    imageFiles: Array<File>;
    onSetImageFiles: (list: Array<File>) => void;
    existingImages: TBlockImageList;
    onRemoveExistingImage: (id: string) => void;
}>;

const ImageUploader_: FC<TProps> = ({
    imageOption,
    imageFiles,
    onSetImageFiles,
    existingImages,
    onRemoveExistingImage,
}: TProps) => {
    const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();

    const imageURLS = React.useMemo<TImageList>(
        () => {
            if (!imageFiles && !existingImages) {
                return [];
            }
            const newImageUrls: TImageList = Array.from(imageFiles).map((x, index) => ({
                id: `${index}`,
                block_image_url: URL.createObjectURL(x)}
            ));
            const existingImageUrls: TImageList = (existingImages ?? []).map(x => ({
                id: `loaded_${x.id}`,
                block_image_url: x.block_image_url
            }));

            return [...existingImageUrls, ...newImageUrls];
        },
        [existingImages, imageFiles],
    );

    const onImageChange = React.useCallback<(e: React.ChangeEvent<HTMLInputElement>) => void>(
        e => {
            const files: FileList | null = e.target.files;

            if(!files) {
                return;
            }
            onSetImageFiles([...imageFiles, ...Array.from(files)]);
        },
        [imageFiles, onSetImageFiles],
    );

    const forwardClickToInput = React.useCallback(
        () => {
            fileInputRef.current!.click();
        },
        [fileInputRef],
    );

    const removeImage = React.useCallback<(id: string) => void>(
        id => {
            if (id.startsWith('loaded')) {
                const imageId = id.split('_')[1]; // result, for example: ['loaded', '34']
                onRemoveExistingImage(imageId);
                return;
            }
            const itemAt = +id;
            onSetImageFiles([
                ...imageFiles.slice(0, itemAt),
                ...imageFiles.slice(itemAt + 1)
            ]);
        },
        [imageFiles, onRemoveExistingImage, onSetImageFiles],
    );

    return (
      <>
        <input 
            type="file" 
            multiple 
            accept="image/*" 
            ref={fileInputRef}
            onChange={onImageChange} 
            hidden={true}
        />
        <ImageList 
            imageOption={imageOption}
            items={imageURLS}
            onRemove={removeImage}
            onForwardClick={forwardClickToInput}
        />
      </>
    );
};

export const ImageUploader = React.memo(ImageUploader_);
