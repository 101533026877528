import create from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";
import { TPublicPage } from "src/types";
import { PageService } from "src/services/api/services/PageService";
import { PageDefaultFont } from "src/constants";

type TPageStore = Readonly<{
    page: TPublicPage | null;
    getPage: (pageName: string) => void;
}>;

export const usePageStore = create(subscribeWithSelector(persist<TPageStore>((set, get) => ({
    page: null,
    getPage: async (pageName: string) => {
        try {
            if (!pageName) {
                return;
            }
            const { request } = PageService.getPublicPage(pageName);
            const response = await request();

            if (response.status !== 200) {
                throw new Error();
            }

            set({ page: {
                ...response.data, 
                page_font: response.data.page_font || PageDefaultFont
            }})
        } catch {
            set({ page: null })
        }
    },
}), {
    name: "page",
    getStorage: () => window.sessionStorage
}
)));
