import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { AuthService } from "src/services/api/services/AuthService";
import { Loader } from "src/ui/loader";
import { Txt } from "src/ui/text";
import styled from "styled-components";

const Container = styled.div`
    margin-top: 200px;
`

const ActivationPage_: React.FC = () => {
    const {hashcode} = useParams();
    const navigate = useNavigate();

    const [isVerified, setIsVerified] = React.useState<boolean | null>(null);

    React.useEffect(
        () => {
            (async () => {
                try {
                    if (!hashcode) {
                        throw new Error();;
                    }
                
                    const { request } = AuthService.activateEmail(hashcode);
                    const response = await request();
                    if (response.status !== 200) {
                        throw new Error();
                    }

                    setIsVerified(response.data.status === 1);
                } catch (Error) {
                    setIsVerified(false);
                }
            })();
        }, [hashcode, navigate],
    );
    
    if (!hashcode) {
        return null;
    }

    if (isVerified === null) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!isVerified) {
        return (
            <Txt textAlign="center" textStyle="h1" marginTop="200px">
                Sorry! Something went wrong, please try again later.
            </Txt>
        );
    }
    return (
        <Txt textAlign="center" textStyle="h1" marginTop="200px">
            Congratulations! Your email address has been successfully verified.
        </Txt>
    );
};

export const ActivationPage = ActivationPage_;
