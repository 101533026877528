import { useOutletContext } from "react-router-dom";

type TContextPage = Readonly<{
    pageName: string;
    isPreview?: boolean;
    onEndPreview?: () => void;
  }>;
  
export function usePage() {
    return useOutletContext<TContextPage>();
}
  