import { APIClient } from "../APIClient";
import { TChangeUsernameRequest } from "../models/ChangeUsernameRequest";
import { TChangeUsernameResponse } from "../models/ChangeUsernameResponse";
import { TCreatePageRequest } from "../models/CreatePageRequest";
import { TCreatePageResponse } from "../models/CreatePageResponse";
import {PagesResponse} from "../models/PagesResponse";
import { getAccessToken } from "./utils/get-access-token";

export namespace CabinetService {
    export function getPages() {
        const token: string = getAccessToken();

        return APIClient.request<
            {},
            PagesResponse
        >({
            method: "GET",
            url: "/cm/pages",
            headers: {
                "Content-Type": "application/problem+json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
    };

    export function changeUserName(userId: string, model: TChangeUsernameRequest) {
        const token: string = getAccessToken();
        return APIClient.request<
        TChangeUsernameRequest,
        TChangeUsernameResponse
      >({
        method: "PUT",
        url: `/cm/users/${userId}`,
        data: model,
        headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
      });
    }

    export function createPage(model: TCreatePageRequest) {
        const token: string = getAccessToken();

        return APIClient.request<
          TCreatePageRequest,
          TCreatePageResponse
      >({
          method: "POST",
          url: "/cm/pages",
          data: model,
          headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
      });
    }
};
