import React, { CSSProperties } from "react";

import { BackgroundColors, Colors } from "src/constants";
import Input from "src/ui/inputs/Input";
import { Txt } from "src/ui/text";
import styled from "styled-components";
import { useFlag } from "src/hooks/useFlag";
import { useTranslation } from "react-i18next";
import { TOnInputCb } from "src/types";
import { SocialIcon } from "react-social-icons";
import SocialLinks from "social-links";
import { Button } from "src/ui/buttons/Button";

type TProps = Readonly<{
    onAddLink: (link: string) => void;
}>;

const LinkButton = styled.button`
    background-color: ${Colors.sysBlue1};
    height: 32px;
    padding: 6px 12px;
    border-radius: 12px;
    display: block;
    margin: 30px auto;
`
const InputContainer = styled.div`
    display: flex;
    align-items: center;
    width: calc(100% - 35px);
`
const Container = styled.div`
    margin-bottom: 30px;
`
const SubContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const SocialIconStyle = {
    width: '30px', 
    height: '30px', 
    pointerEvents: 'none',
    marginRight: '5px'
};

const socialLinks = new SocialLinks();

const AddSocialLink_: React.FC<TProps> = ({
    onAddLink
}: TProps) => {
    const { t } = useTranslation();

    const [isAddLinkVisible, , closeAddLink, toggleAddLinkVisibility] = useFlag();
    const [newSocialLink, setNewSocialLink] = React.useState<string>('');

    const isValid = React.useMemo<boolean>(
        () => {
            const profileName = socialLinks.detectProfile(newSocialLink);
            return socialLinks.isValid(profileName, newSocialLink);
        },
        [newSocialLink],
    );

    const changeNewSocialLink = React.useCallback<TOnInputCb>(
        e => {
            setNewSocialLink(e.target.value);
        },
        [],
    );

    const addLink = React.useCallback(
        () => {
            if (!isValid) {
                return;
            }
            onAddLink(newSocialLink);
            closeAddLink();
            setNewSocialLink('');
        },
        [closeAddLink, isValid, newSocialLink, onAddLink],
    );

    return (
        <Container>
            <LinkButton onClick={toggleAddLinkVisibility}>
                <Txt textStyle='uppercase' color={BackgroundColors.bgWhite}>
                    {t('BTN_ADD_SOCIAL_LINK')}
                </Txt>
            </LinkButton>
            {isAddLinkVisible && (
                <SubContainer>
                    {isValid && (
                        <SocialIcon 
                            bgColor={Colors.sysBlue1} 
                            style={SocialIconStyle as CSSProperties} 
                            url={newSocialLink} 
                        />
                    )}
                    <InputContainer>
                        <Input 
                            type="text"
                            name="social_link"
                            value={newSocialLink}
                            label={t('TEXT_SOCIAL_URL')}
                            onChange={changeNewSocialLink}
                        />
                        <Button 
                            buttonStyle="sm"
                            margin="0 0 0 10px" 
                            isDisabled={!isValid} 
                            label={t('BTN_ADD') || undefined} 
                            onClick={addLink}
                        />
                    </InputContainer>
                </SubContainer>
            )}
        </Container>
    );
};

export const AddSocialLink = React.memo(AddSocialLink_);
