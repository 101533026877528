interface IBlockPosition {
  block_position: string
}

const compareFn = <T extends IBlockPosition>(a: T, b: T): number => {
  if (+a.block_position < +b.block_position) {
      return -1;
    }
    if (+a.block_position > +b.block_position) {
      return 1;
    }
    return 0;
};

export const sortBlocks = <T extends IBlockPosition>(list: Array<T>): Array<T> => {
  return list.sort(compareFn);
};
