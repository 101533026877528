import React from "react";

import { TPublicPage } from "src/types";
import { usePageStore } from "src/store/usePage";
import shallow from "zustand/shallow";

type TOut = Readonly<{
    isLoaded: boolean;
    pageBackground: string | null;
}>;

export const usePageState = (pageName: string): TOut => {
    const [pageBackground, setPageBackground] = React.useState<string | null>(null);
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

    const onPageLoaded = React.useCallback<(selectedState: TPublicPage | null) => void>(
        selectedState => {
            if (selectedState !== null && selectedState?.page_address !== pageName) {
                return;
            }
            setIsLoaded(selectedState !== null);

            if (selectedState && selectedState.page_background_color) {
                setPageBackground(selectedState.page_background_color);
            }
        },
        [pageName],
    );

    React.useEffect(() => {
        const unsubscribe = usePageStore.subscribe(
            (state) => state.page,
            (curr, _) => onPageLoaded(curr),
            { equalityFn: shallow }
        );

        return () => {
            unsubscribe();
        };
    }, [onPageLoaded]);

    return { isLoaded, pageBackground };
}
