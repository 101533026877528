import React from "react";

import { ScreenSizes } from "src/constants";
import Logo from "src/ui/images/Logo";
import styled from "styled-components";
import s from "./style.module.sass";
import { PagePreviewButton } from "src/components/blocks/PagePreviewButton";

type TProps = Readonly<{
    isSticky?: boolean;
    pageAddress?: string;
    onClick?: () => void;
    pageWidth?: number;
}>;

const HeaderInner = styled.header`
    display: none;
    pointer-events: none;
    @media (min-width: ${ScreenSizes.lg}) {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 102px;
        padding: 20px;
}`

const HeaderSticky = styled.header`
    min-height: 72px;
`

const Header_: React.FC<TProps> = ({
    isSticky = false,
    pageAddress,
    onClick,
    pageWidth
}: TProps) => {
    const [offsetY, setOffsetY] = React.useState<number>(0);
    const classNameFinal: string = [
        "d-flex sticky-top bg-white p-4",
        (offsetY > 0 ? 'fade-in shadow-sm' : 'fade-out')
    ].join(' ')

    const handleScroll = React.useCallback(
        () => {
            setOffsetY(window.pageYOffset);
        },
        [],
    );

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    if (isSticky && onClick) {
        return (
            <HeaderSticky className={classNameFinal}>
                <Logo className={s.stickyLogo} />
                <PagePreviewButton 
                    pageAddress={pageAddress || ""} 
                    onClick={onClick} 
                    width={pageWidth || 0}
                />
            </HeaderSticky>
        );
    }

    return (
        <HeaderInner>
            <Logo className={s.logo} />
        </HeaderInner>
    )
};

export const Header = Header_;
