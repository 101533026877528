import React from "react";

import { object } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { passwordSchema, usernameSchema } from "src/validators";
import { RegisterRequest, TRegisteredCard } from "src/types";
import { CardService } from "src/services/api/services/CardService";
import { useIsMounted } from "src/hooks/useIsMounted";
import { useProfileStore } from "src/store/useProfileStore";
import { useTranslation } from "react-i18next";

type Form = {
  username: string;
  password: string;
  cardCode?: string;
};

type TInProps = Readonly<{
  onSubmit: (data: RegisterRequest) => void;
}>;

export default function useSignUp({onSubmit}: TInProps) {
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const storedCard: TRegisteredCard | null = useProfileStore((state) => state.selectedCard);
  const selectCard = useProfileStore((state) => state.selectCard);

  const [isLoading, setLoading] = React.useState<boolean>(false);

  const {
    control,
    formState,
    reset,
    setError,
    handleSubmit,
  } = useForm<Form>({
    mode: "all",
    defaultValues: {
      username: "",
      password: "",
      cardCode: storedCard?.key,
    },
    resolver: yupResolver(object({
      username: usernameSchema,
      password: passwordSchema,
    })),
  });

  const isDisabled = (() => {
    return formState.isValid === false;
  })();

  const errors = (() => {
    return {
      username: formState.errors.username?.message as string | undefined,
      password: formState.errors.password?.message as string | undefined,
      cardCode: formState.errors.cardCode?.message as string | undefined,
    }
  })();

  const submit = React.useCallback<(data: Form) => void>(
    ({username, password, cardCode}) => {
      if (cardCode && cardCode.length) {
        const cardId = +cardCode;

        (async () => {
          try {
            if(isMounted()) {
              setLoading(true);
            }
            const { request } = CardService.getCard(cardId);
            const response = await request();
            if (response.status !== 200) {
              throw new Error();
            }
  
            if (isMounted()) {
              const {page_address, users_id} = response.data;
  
              const isLinkedToPage = !!(page_address && page_address.length > 0);
              const isActive = !!(users_id && users_id.length > 0);
  
              if (isActive) {
                setError("cardCode", {message: t('CARD_ERROR_OTHER_USER') || "Card already linked to another user"});
                setLoading(false);
                return;
              }
              if (isLinkedToPage) {
                setError("cardCode", {message: t('CARD_ERROR_OTHER_URL') || "Card already linked to another page"});
                setLoading(false);
                return;
              }

              setLoading(false);
              onSubmit({
                email: username,
                password: password,
                card_code: cardCode
              });
              reset();
            }
          } catch (Error) {
            if (isMounted()) {
              setError("cardCode", {message: t('CARD_ERROR_DOESNT_EXIST') || "Card not found"});
              setLoading(false);
            }
          }
        })();
      } else {
        onSubmit({
          email: username,
          password: password,
          card_code: cardCode
        });
      }
    },
    [isMounted, onSubmit, reset, setError, t]
  );
  
  React.useEffect(
    () => () => {
      selectCard(null);
    }, [selectCard]
  );
  return {
    errors,
    control,
    isLoading,
    isDisabled,
    submit: handleSubmit(data => submit(data))
  };
}
