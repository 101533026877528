import React, { FC } from "react";

import c from "classnames";
import Input from "src/ui/inputs/Input";
import LocalImage from "src/ui/images/LocalImage";
import MainButton from "src/ui/buttons/MainButton";
import {PagesBlock} from "src/components/blocks/PagesBlock";
import s from "./style.module.sass";
import { CabinetService } from "src/services/api/services/CabinetService";
import { Loader } from "src/ui/loader";
import { AuthService } from "src/services/api/services/AuthService";
import { TPageList, TRegisteredCard, TRegisteredCardList, TSelectList, TUserInfo } from "src/types";
import { useTranslation } from "react-i18next";
import { useFlag } from "src/hooks/useFlag";
import { CreatePageForm } from "../../components/forms/CreatePageForm";
import { useNavigate } from "react-router-dom";
import { useProfileStore } from "src/store";
import { useIsMounted } from "src/hooks/useIsMounted";
import { Txt } from "src/ui/text";
import styled from "styled-components";
import { BackgroundColors, Colors, DefaultReleasedCard } from "src/constants";
import { Cards } from "./Cards";
import { CardService } from "src/services/api/services/CardService";
import { PhysicalCard } from "./PhysicalCard";
import { TLinkCardCb, TLinkCardToUrlCb } from "./types";
import { StandardButton } from "src/ui/buttons/StandardButton";

const TitleContainer = styled.div`
  display: flex;
  margin: 0 0 20px 0;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

interface IPageButtonProps {
  width?: number;
}

const PageButton = styled.button<IPageButtonProps>`
  background-color: ${Colors.sysBlue1};
  border-radius: 12px;
  padding: 6px 12px;
  height: 32px;
  width: ${({ width }) => typeof width === 'number' ? `${width}px)` : 'unset'};
`

const PageAdminHome_: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectUser = useProfileStore((state) => state.selectUser);
  const selectCard = useProfileStore((state) => state.selectCard);

  // const storedCard: TRegisteredCard | null = useProfileStore((state) => state.selectedCard);

  const isMounted = useIsMounted();
  const [isPageModalVisible, openPageModal, closePageModal] = useFlag();

  const [user, setUser] = React.useState<TUserInfo | null>(null);
  const [userName, setUserName] = React.useState<string>("");
  const [pages, setPages] = React.useState<TPageList | null>(null);
  const [cards, setCards] = React.useState<TRegisteredCardList | null>(null);
  const [selectedCard, setSelectedCard] = React.useState<TRegisteredCard | null>(null);
  const [isLoadingCard, setIsLoadingCard] = React.useState<boolean>(false);

  const hasNotVerifiedEmail = user !== null && user?.state !== "1";

  const pageOptions = React.useMemo<TSelectList>(
    () => (pages && pages.length > 0) 
          ? pages.map(x => ({ value: x.id, label: x.page_address_full})) 
          : [],
    [pages],
  );

  const selectReleasedCard = React.useCallback(
    () => {
      setSelectedCard(DefaultReleasedCard);
    },
    [],
);

  const deselectCard = React.useCallback(
    () => {
      setSelectedCard(null);
      selectCard(null);
    },
    [selectCard],
  );

  const changeUserName = React.useCallback<(e: React.ChangeEvent<HTMLInputElement>) => void>(
    e => {
      setUserName(e.target.value);
    }, [],
  );

  const saveUserName = React.useCallback(
    () => {
      if (!user || +user.user_id < 0) {
        return;
      }

      if (userName === user.username) {
        return;
      }

      (async () => {
        try {
          const { request } = CabinetService.changeUserName(user?.user_id, {
            username: userName,
          });
          const response = await request();

          if (response.status !== 200) {
            throw new Error();
          }

          if (!isMounted()) {
            return;
          }
          if(response.data?.username) {
            setUserName(response.data?.username);
            setUser({
              ...user,
              username: response.data?.username
            });
            selectUser({
              ...user,
              username: response.data?.username
            });
            return;
          }

          setUserName(user.username);
        } catch (Error) {
          if (isMounted()) {
            setUserName(user.username);
          }
        }
      })();
    }, [isMounted, selectUser, user, userName],
  );

  const reloadPages = React.useCallback(
    () => {
      (async () => {
        try {
          setPages(null);
          const { request } = CabinetService.getPages();
          const response = await request();

          if (response.status !== 200) {
            throw new Error();
          }

          if (isMounted()) {
            setPages(response.data._embedded.pages);
          }
        } catch (Error) {
          if (isMounted()) {
            setPages([]);
          }
        }
      })();
    },
    [isMounted],
  );

  const resendVerificationLink = React.useCallback(
    () => {
      (async () => {
        try {
          const { request } = AuthService.resendVerificationLink();
          const response = await request();

          if (response.status !== 200) {
            throw new Error();
          }
        } catch (Error) {
        }
      })();
    },
    [],
  );

  React.useEffect(
    () => {
      reloadPages();
    }, [reloadPages],
  );

  const getUserCard = React.useCallback<(cardId: number) => void>(
    cardId => {
      (async () => {
        try {
          if(isMounted()) {
            setIsLoadingCard(true);
          }
          const { request } = CardService.getCard(cardId);
          const response = await request();

          if (response.status !== 200) {
            throw new Error();
          }

          if(isMounted()) {
            if (typeof response.data.users_id === 'string') {
              if (response.data.users_id === user?.user_id) {
                setSelectedCard({...DefaultReleasedCard, ...response.data});
              } else {
                console.log('card is owned by other user');
                setSelectedCard(DefaultReleasedCard); // to keep modal opened
              }
              setIsLoadingCard(false);
              return;
            }

            setSelectedCard({...DefaultReleasedCard, ...response.data});
            setIsLoadingCard(false);
          }
        } catch (Error) {
          setSelectedCard(DefaultReleasedCard); // to keep modal opened
          setIsLoadingCard(false);
        }
      })();
    },
    [isMounted, user?.user_id],
  );

  const getUserCards = React.useCallback(
    () => {
      (async () => {
        try {
          const { request } = CardService.getUserCards();
          const response = await request();
          if (response.status !== 200) {
            throw new Error();
          }

          if(isMounted()) {
            setCards(response.data._embedded.users_card);
          }
        } catch (Error) {
        }
      })();
    },
    [isMounted],
  );

  const linkCardToUrl = React.useCallback<TLinkCardToUrlCb>(
    ({cardKey, cardId, url, pageId}) => {
      (async () => {
        try {
          const { request } = CardService.linkCardToUrl(cardId, {
            key: cardKey,
            page_address: url,
            pages_id: pageId
          });

          const response = await request();
          if (response.status !== 200) {
            throw new Error();
          }
          getUserCards();
        } catch (Error) {
          getUserCards();
        }
      })();
    },
    [getUserCards],
  );

  const linkCardToProfileAndPage = React.useCallback<TLinkCardCb>(
    ({cardKey, type, url, pageId}) => {
      (async () => {
        try {
          const { request } = CardService.linkCardToProfile({
            key: cardKey,
            card_type: type
          });

          const response = await request();
          if (response.status !== 201) {
            throw new Error();
          }

          if (url && response.data.id) {
            linkCardToUrl({cardKey, cardId: response.data.id, url, pageId});
          } else {
            getUserCards();
          }
        } catch (Error) {
          getUserCards();
        }
      })();
    },
    [getUserCards, linkCardToUrl],
  );

  React.useEffect(
    () => {
      (async () => {
        try {
          const { request } = AuthService.getUsers();
          const response = await request();

          if (response.status !== 200) {
            throw new Error();
          }

          const {users} = response.data._embedded;
          if (users.length > 1) {
            throw new Error();
          }

          if (isMounted()) {
            setUser(users[0]);
            selectUser(users[0]);
            setUserName(users[0].username);
            getUserCards();
          }
        } catch (Error) {
          navigate('/signin');
        }
      })();
    }, [getUserCards, isMounted, navigate, selectUser],
  );

  return (
    <>
      <div className={s.header}>
        <LocalImage src="home.svg" alt="home" className={s.home} />
        <Txt 
          textStyle="h3" 
          className="d-block text-truncate" 
          maxWidth="250px"
          lgMaxWidth="600px"
          marginLeft="10px"
        >
          ntmy.pro/{user?.username ?? ''}
        </Txt>
      </div>
      {hasNotVerifiedEmail && (
        <section className={s.section}>
          <Txt textAlign="center">{`${t('TEXT_VERIFY_ACCOUNT')} (${user?.email}).`}</Txt>
          <StandardButton 
            height="32px" 
            className="py-0 m-auto my-3"
            onClick={resendVerificationLink}
            label={t('TEXT_RESEND_VERIFICATION_LINK')} 
            labelStyle="body_14"
          />
        </section>
      )}
      <section className={s.section}>
        <h3 className={c(s.sectionTitle)}>{t('TITLE_MY_INFO')}</h3>
        <Input
          name="username"
          type="text"
          value={userName}
          label={t('FIELD_NAME')}
          className={s.input}
          onChange={changeUserName}
        />
        <Input
          name="email"
          type="email"
          value={user?.email ?? ''}
          label={t('FIELD_EMAIL')}
          disabled
          className={s.input}
          onChange={null}
        />
        <MainButton className={s.saveUserNameButton} onClick={saveUserName}>{`${t('BTN_SAVE')}`}</MainButton>
      </section>
      <section className={s.section}>
        <TitleContainer>
          <h3 className={c(s.sectionTitleInBox)}>{t('TITLE_CARDS')}</h3>
          <PageButton onClick={selectReleasedCard}>
              <Txt textStyle="uppercase" color={BackgroundColors.bgWhite}>
                Add existing card +
              </Txt>
          </PageButton>
        </TitleContainer>
        <Cards list={cards || []} onSelectCard={setSelectedCard} />
        <MainButton className={c(s.mainButton)} labelClassName={c(s.mainButtonLabel)}>
          {t('TITLE_CREATE_CARD')}
        </MainButton>
        <MainButton className={c(s.mainButton2)} labelClassName={c(s.mainButtonLabel2)}>
          {t('TITLE_OTHER_CARDS')}
        </MainButton>
      </section>
      <section className={s.section}>
        <TitleContainer>
          <h3 className={c(s.sectionTitleInBox)}>{t('TITLE_MY_PAGES')}</h3>
          <PageButton width={99} onClick={openPageModal}>
              <Txt textStyle="uppercase" color={BackgroundColors.bgWhite}>
              {t('BTN_CREATE')} +
              </Txt>
          </PageButton>
        </TitleContainer>
        {(pages !== null && user !== null) ? (
          <PagesBlock userId={+user.user_id} userName={user.username} list={pages} />
        ) : (
          <Loader />
        )}
      </section>
      {isPageModalVisible &&(
        <CreatePageForm 
            onReloadPages={reloadPages}
            onClose={closePageModal} 
        />
      )}
      {selectedCard !== null && (
        <PhysicalCard 
          isLoading={isLoadingCard}
          item={selectedCard} 
          pageOptions={pageOptions}
          onLinkCardToUrl={linkCardToUrl}
          linkCardToProfileAndPage={linkCardToProfileAndPage}
          loadCard={getUserCard}
          onClose={deselectCard}
        />
      )}
    </>
  );
};

export const PageAdminHome = React.memo(PageAdminHome_);
