import React from "react";

import { useFlag } from "src/hooks/useFlag";
import { TSelectItem, TSelectList } from "src/types";
import { Txt } from "../text";
import { BackgroundColors, ButtonColors, TextColors } from "src/constants";
import styled from "styled-components";
import { SelectorItem } from "./SelectorItem";
import arrowDown from 'src/assets/img/arrowDown.svg';
import { useTranslation } from "react-i18next";


type TProps = Readonly<{
    list: TSelectList;
    selectedValue: string;
    label: string;
    onSelect: (value: string) => void;
    isDisabled?: boolean;
    hasDefaultOption?: boolean;
}>;

const Container = styled.div`
  width: 100%;
  position: relative;
`

const Dropdown = styled.div`
  display: block;
  width: 100%;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${ButtonColors.secondaryActive};
  border-radius: 12px;
  padding: 0 20px;
  color: ${TextColors.dark1};
  flex-direction: column;
  background-color: ${BackgroundColors.bgWhite};
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 99;
`

const SelectField = styled.div`
  height: 64px;
  padding-top: 4px;
`
const SelectButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${ButtonColors.secondaryActive};
  border-radius: 12px;
  padding: 0 20px;
  background-color: ${BackgroundColors.bgWhite};
  transition: border-color 0.3s ease-in-out;
  &:focus {
    border-color: ${ButtonColors.active};
  };
  &:disabled {
    cursor: not-allowed;
    border-color: ${ButtonColors.secondaryActive};
  };
`

const Icon = styled.div`
  background-image: url(${arrowDown})};
  width: 24px;
  height: 24px;
  padding: 2px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: contain;
`

const Selector_: React.FC<TProps> = ({
    list,
    selectedValue,
    label,
    onSelect,
    isDisabled = false,
    hasDefaultOption = false,
}: TProps) => {
  const { t } = useTranslation();
    const ref = React.createRef<HTMLDivElement>();
    const [isDropdownOpened, , closeDropdown, toggleDropdown] = useFlag();

    const defaultOption = React.useMemo<TSelectItem>(
      () => ({
          value: "", 
          label: t('SELECT_OPTION')
      }),
      [t]
    );

    const listFinal = React.useMemo<TSelectList>(
      () => hasDefaultOption ? [defaultOption, ...list] : list,
      [defaultOption, hasDefaultOption, list],
    );

    const selectedLabel = React.useMemo<string>(
      () => listFinal.find(x => x.value === selectedValue)?.label || "",
      [listFinal, selectedValue]
    );

    const selectLabelColor = React.useMemo<string>(
      () => {
        if (isDisabled) {
          return TextColors.dark4;
        }
        return selectedValue.length === 0 ? TextColors.dark3 : TextColors.dark1
      },
      [isDisabled, selectedValue.length],
    );

    const select = React.useCallback<(value: string) => void>(
      value => {
        onSelect(value);
        closeDropdown();
      },
      [closeDropdown, onSelect]
    );

    const blur = React.useCallback<(e: MouseEvent) => void>(
      e => {
        if (!ref.current) {
          return;
        }

        if (!ref.current.contains(e.target as Node) && isDropdownOpened) {
          closeDropdown();
        }
      },
      [closeDropdown, isDropdownOpened, ref],
    );

    React.useEffect(
      () => {
        window.addEventListener('click', blur);
    
        return () => {
          window.removeEventListener('click', blur);
        };
      }, 
      [blur]
    );

    return (
      <Container ref={ref}>
        <SelectField>
            <Txt 
              textStyle="body_secondary" 
              position="absolute" 
              top="calc(calc(8px - 20px) / 2)"
              left="calc(20px - 8px)"
              transition = "top 0.25s ease, font-size 0.25s ease-in-out"
              padding="0 8px"
              userSelect="none"
              pointerEvents="none"
              color={isDisabled ? TextColors.dark4 : TextColors.dark2}
              backgroundColor={BackgroundColors.bgWhite}
            >
              {label}
            </Txt>
            <SelectButton disabled={isDisabled} onClick={toggleDropdown}>
              <Txt 
                color={selectLabelColor}
                textAlign="start"
                textStyle="body_16"
              >
                {selectedLabel}
              </Txt>
              <Icon />
            </SelectButton>
        </SelectField>
        {isDropdownOpened && (
          <Dropdown>
                {listFinal.map((item) =>
                  <SelectorItem key={item.value} {...item} onClick={select} />
                )}
          </Dropdown>
        )}
      </Container>
    );
};

export const Selector = React.memo(Selector_);
