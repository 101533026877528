import shallow from "zustand/shallow";
import { object } from "yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAuth } from "src/store/useAuth";
import { passwordSchema, usernameSchema } from "src/validators";
import { AuthService } from "src/services/api/services/AuthService";
import { useIsMounted } from "src/hooks/useIsMounted";

type Form = {
  username: string;
  password: string;
}

export default function useSignIn() {
  const {
    setTokenType,
    setAccessToken,
    setRefreshToken,
    clear
  } = useAuth((state) => ({
    setTokenType: state.setTokenType,
    setAccessToken: state.setAccessToken,
    setRefreshToken: state.setRefreshToken,
    clear: state.clear
  }), shallow);

  const naviagte = useNavigate();
  const isMounted = useIsMounted();
  
  const [isLoading, setLoading] = useState(false);

  const {
    control,
    formState,
    reset,
    getValues,
    handleSubmit,
  } = useForm<Form>({
    mode: "all",
    defaultValues: {
      username: "",
      password: ""
    },
    resolver: yupResolver(object({
      username: usernameSchema,
      password: passwordSchema
    })),
  });

  const isDisabled = (() => {
    return formState.isValid === false;
  })();

  const errors = (() => {
    return {
      username: formState.errors.username?.message as string | undefined,
      password: formState.errors.password?.message as string | undefined,
    }
  })();

  const loginAction = async () => {
    const fields = getValues();

    const { request } = AuthService.login({
      username: fields.username,
      password: fields.password
    });

    const response = await request();
    const data = response.data.data;

    if (response.data.status !== 1 || data === undefined) {
      throw Error("Failed request");
    }

    if (isMounted()) {
      setTokenType(data.token_type);
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
    }
  }

  const onSubmitHandler = async () => {
    if (isMounted()) {
      setLoading(true);
    }
    try {
      await loginAction();
      reset();
      naviagte("/admin");
    } catch (error) {
      console.log(error);
    }

    if (isMounted()) {
      setLoading(false);
    }
  };

  useEffect(() => {
    clear();
  }, [clear]);

  return {
    errors,
    control,
    isLoading,
    isDisabled,
    onSubmit: handleSubmit(onSubmitHandler)
  };
}
