import React from "react";

import { usePageStore } from "src/store/usePage";
import { TPublicPage } from "src/types";
import shallow from "zustand/shallow";

type TIn = Readonly<{
    pageName: string;
    onPageLoaded: (selectedState: TPublicPage | null) => void;
}>;

type TOut = Readonly<{
    loadPage: () => void;
}>;

export const usePageState = (data: TIn): TOut => {
    const { pageName, onPageLoaded } = data;
    const getPage = usePageStore((state) => state.getPage);

    const loadPage = React.useCallback(
        () => {
            if (!pageName) {
                return;
            }
            getPage(pageName);
        },
        [getPage, pageName]
    );

    React.useEffect(() => {
        const unsubscribe = usePageStore.subscribe(
            (state) => state.page,
            (curr, _) => onPageLoaded(curr),
            { equalityFn: shallow }
        );
        return () => {
            usePageStore.setState({ page: null });
            unsubscribe();
        };
    }, [onPageLoaded]);

    return { loadPage };
}
