import React, { FC } from "react";

import styled from "styled-components";
import { TSliderColorItem } from "src/types";
import { Colors } from "src/constants";

type TProps = TSliderColorItem & Readonly<{
    onClick: (value: string) => void;
    isActive: boolean;
    width: string;
    height: string;
}>;

interface ButtonProps {
    bgColor: string;
    width: string;
    height: string;
    borderColor?: string;
}

const Container = styled.div`
    display: block;
    width: 100%;
`

const Button = styled.button<ButtonProps>`
    display: block;
    border-radius: 30px;
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    background-color: ${({ bgColor }) => bgColor};
    border: ${({ borderColor }) => typeof borderColor === 'string' ? `2px solid ${borderColor}` : 'unset'};
`
const SliderColorItem_: FC<TProps> = ({
    isActive,
    value,
    bgColor,
    onClick,
    width,
    height,
}: TProps) => {
    const click = React.useCallback(
        () => {
            onClick(value);
        },
        [onClick, value],
    );

    const borderColorFinal = isActive ? Colors.sysBlue1 : undefined;
    return (
        <Container>
            <Button 
                bgColor={bgColor}
                borderColor={borderColorFinal} 
                onClick={click}
                width={width}
                height={height}
            >
            </Button>
        </Container>
    );
};

export const SliderColorItem = React.memo(SliderColorItem_);
