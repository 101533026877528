import { TRegisteredCard } from "src/types";
import { APIClient } from "../../APIClient";
import { getAccessToken } from "../utils/get-access-token";
import { TGetUserCardsResponse, TLinkCardToProfileRequest, TLinkCardToProfileResponse, TLinkCardToUrlRequest } from "./types";

export namespace CardService {
    export function getCard(cardId: number) {
      return APIClient.request<{}, TRegisteredCard>({
          method: "GET",
          url: `/users_card/${cardId}`,
          headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
          }
      });
    };

    export function getUserCards() {
      const token: string = getAccessToken();

      return APIClient.request<{}, TGetUserCardsResponse>({
          method: "GET",
          url: `/cm/users_card`,
          headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
          }
      });
    };

    export function linkCardToUrl(cardId: string, model: TLinkCardToUrlRequest) {
      const token: string = getAccessToken();

      return APIClient.request<
      TLinkCardToUrlRequest, 
      any
      >({
          method: "PUT",
          url: `/cm/users_card/${cardId}`,
          data: model,
          headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
          }
      });
    };

    export function linkCardToProfile(model: TLinkCardToProfileRequest) {
      const token: string = getAccessToken();

      return APIClient.request<
      TLinkCardToProfileRequest, 
      TLinkCardToProfileResponse
      >({
          method: "POST",
          url: '/cm/users_card',
          data: model,
          headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
          }
      });
    };
};
