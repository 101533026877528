import React from "react";

import styled from "styled-components";
import iconChecked from 'src/assets/img/checkboxChecked.svg';
import iconUnchecked from 'src/assets/img/checkboxUnchecked.svg';
import { Txt } from "../text";
import { TTextStyle } from "src/types";

type TProps = Readonly<{
    value: boolean;
    onChange: (value: boolean) => void;
    label?: string;
    labelStyle?: TTextStyle;
    children?: React.ReactNode;
}>;

interface IIconProps {
    image: string,
}

const Icon = styled.div<IIconProps>`
    background-image: ${({ image }) => `url(${image})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 48px;
    width: 48px;
    background-color: transparent;
    margin-right: 10px;
`

const Container = styled.button`
    display: flex;
    width: 100%;
    align-items: center;
    background-color: transparent;
`

const Checkbox_: React.FC<TProps> = ({
    value,
    onChange,
    label,
    labelStyle,
    children,
}: TProps) => {
    const onClick = React.useCallback(
        () => {
            onChange(!value);
        },
        [onChange, value],
    );

    return (
        <Container onClick={onClick}>
            <Icon image={value ? iconChecked : iconUnchecked} />
            {!!label && (<Txt textStyle={labelStyle}>{label}</Txt>)}
            {children}
        </Container>
    )
};

export const Checkbox = React.memo(Checkbox_);
