import { FC } from "react";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  isLoading?: boolean;
  isDisabled?: boolean;
  className?: string;
  labelClassName?: string;
  onClick?: () => void;
};

const _loaderItemsArray = Array.from(Array(12).keys());

const Loader: FC = () => {
  const items = _loaderItemsArray.map((value) => {
    return (
      <div
        key={value}
        className={s.spinnerItem}
      />
    );
  });

  return (
    <div className={s.spinner}>
      { items }
    </div>
  );
};

const MainButton: FC<Props> = ({
  isLoading = false,
  isDisabled = false,
  className,
  children,
  labelClassName,
  onClick,
}) => {
  const disabledClass = (() => {
    if (isDisabled === false) return;
    return s.buttonDisabled;
  })();

  const _onClick = () => {
    if (isLoading !== false) return;
    if (isDisabled !== false) return;
    if (onClick === undefined) return;

    onClick();
  };

  const titleElement = (
    <p className={c(labelClassName, s.label)}>
      { children }
    </p>
  );

  return (
    <button
      className={c(className, s.button, disabledClass)}
      type="button"
      onClick={_onClick}
    >
      { isLoading
        ? <Loader />
        : titleElement
      }
    </button>
  );
};

export default MainButton;
