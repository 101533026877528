import React from "react";

import { TabletScreenWidth } from "src/constants";
import styled from "styled-components";

type TWrapInnerProps = Readonly<{
    padding: string;
}>;

type TProps = TWrapInnerProps & Readonly<{
    children: React.ReactNode;
    wrapperRef?: React.RefObject<HTMLDivElement> | null;
}>;

const WrapInner = styled.div<TWrapInnerProps>`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    padding: ${({ padding }) => padding};
    @media (min-width: ${TabletScreenWidth}px) {
      max-width: ${TabletScreenWidth}px;
      margin: 0 auto;
    }
`

const Wrapper_: React.FC<TProps> = ({
    padding,
    children,
    wrapperRef
}: TProps) => {
    return (
        <WrapInner ref={wrapperRef} padding={padding}>
            {children}
        </WrapInner>
      );
};

export const Wrapper = React.memo(Wrapper_);
