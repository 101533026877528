import { FC } from "react";
import c from "classnames";

import LocalImage from "src/ui/images/LocalImage";

type Props = {
  className?: string;
}

const Logo: FC<Props> = ({
  className
}) => {
  return <LocalImage
    className={c(className)}
    src="logo.svg"
    alt="NTMY"
  />
};

export default Logo;