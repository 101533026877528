import styled from "styled-components";

type TContainerProps = Readonly<{
    backgroundColor: string;
}>;

type TProps = TContainerProps & Readonly<{
    children: React.ReactNode;
}>;

const MainInner = styled.div<TContainerProps>`
    background-color: ${({ backgroundColor }) => backgroundColor};
`

const Main_: React.FC<TProps> = ({
    backgroundColor,
    children
}: TProps) => {
    return (
        <MainInner backgroundColor={backgroundColor} >
            {children}
        </MainInner>
    );
};

export const Main = Main_;
