import React from "react";

import { PublicPageLayout } from "./PublicPageLayout";
import { PrivatePageLayout } from "./PrivatePageLayout";
type TProps = Readonly<{
    pageName: string;
}>;

const PageLayoutInner_: React.FC<TProps> = ({
    pageName
}: TProps) => {
    const isPrivatePage = /\/admin/gm.test(window.location.pathname);

    if (isPrivatePage) {
      return (
        <PrivatePageLayout pageName={pageName} />
      )
    }

    return (
      <PublicPageLayout pageName={pageName} />
    )
};

export const PageLayoutInner = PageLayoutInner_;
