import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import LocalImage from "src/ui/images/LocalImage";
import styles from "./style.module.sass";

type TProps = Readonly<{
    onClick: () => void;
}>;

const TipBlock_: FC<TProps> = ({
    onClick
}: TProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.tipContainer}>
            <button onClick={onClick} className={styles.close}>
                <LocalImage src="close.svg" alt="close" />
            </button>
            <div className={styles.tipTitle}>{t('TITLE_CUSTOMIZE_PAGE')}</div>
            <div className={styles.tipDesc}>{t('TEXT_CUSTOMIZE_PAGE')}</div>
        </div>
    )
};

export const TipBlock = React.memo(TipBlock_);
