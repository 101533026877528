import React, { FC } from "react";

import { object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Input from "src/ui/inputs/Input";
import styles from "./style.module.sass";
import { pageNameSchema } from "src/validators";
import { CabinetService } from "src/services/api/services/CabinetService";
import { StandardModal } from "src/components/modals/StandardModal";

type TProps = Readonly<{
    onReloadPages: () => void;
    onClose: () => void;
}>;

type TForm = Readonly<{
    pageName: string;
}>;

const CreatePageForm_: FC<TProps> = ({
    onReloadPages,
    onClose,
}: TProps) => {
    const { t } = useTranslation();
    const {
        control,
        formState,
        reset,
        getValues,
        handleSubmit,
      } = useForm<TForm>({
        mode: "all",
        defaultValues: {
          pageName: "",
        },
        resolver: yupResolver(object({
          pageName: pageNameSchema,
        })),
      });

    const errorMessage: string | undefined = formState.errors.pageName?.message;

    const createPage = React.useCallback(
        () => {
        (async () => {
            try {
                const fields = getValues();
                const { request } = CabinetService.createPage({
                    page_address: fields.pageName,
                    page_title: "",
                    page_description: "",
                    page_preview: "",
                });
                const response = await request();
                
                if (response.status !== 201) {
                    throw new Error();
                }

                reset();
                onReloadPages();
            } catch (Error) {
                reset();
            }
        })();
        },
        [getValues, onReloadPages, reset],
    );

    const submit = React.useCallback(
        () => {
            handleSubmit(createPage)();
            onClose();
        },
        [createPage, handleSubmit, onClose],
    );

    return (
        <StandardModal 
            isSubmitDisabled={!formState.isValid} 
            header={`${t('BTN_NEW_PAGE')}`} 
            headerClassName="ms-auto"
            headerTextStyle="h1"
            onSubmit={submit} 
            onCancel={onClose} 
            onClose={onClose}
        >
            <form onSubmit={(e) => e.preventDefault()}>
                <Controller
                    name="pageName"
                    control={control}
                    render={({ field }) =>
                    <Input
                        type="text"
                        name={field.name}
                        value={field.value}
                        error={errorMessage}
                        label={`${t('FIELD_PAGE_ADDRESS')}`}
                        className={styles.input}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                    />
                }
                />
            </form>
        </StandardModal>
    )
};

export const CreatePageForm = React.memo(CreatePageForm_);
