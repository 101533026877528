import React from "react";

import { TIcon } from "src/types";
import styled from "styled-components";
import { useLongPress } from "use-long-press";

type TProps = TIcon;

interface IIconProps {
    src: string;
}

const IconInner = styled.button<IIconProps>`
    background-image: ${({ src }) => `url(${src})`};
    width: 17px;
    height: 17px;
    padding: 2px;
    margin-left: -2px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-size: contain;
`

const IconItem_: React.FC<TProps> = ({
    src,
    onClick,
    onLongClick,
}: TProps) => {
    const bind = useLongPress(() => {
        if (onLongClick) {
            onLongClick();
        }
    });
    
    if (onLongClick) {
        return (
            <IconInner
                src={src}
                onClick={onClick}
                className="ignore-elements"
                {...bind()}
        />
        )
    }

    return (
        <IconInner src={src} onClick={onClick} />
    )
};

export const IconItem = React.memo(IconItem_);
