import React from "react";

type TOut = Readonly<{
    width: number;
    height: number;
}>;

const getWindowDimensions = (): TOut => {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
};

export const useWindowDimensions = (): TOut => {
    const [windowDimensions, setWindowDimensions] = React.useState(
        getWindowDimensions()
    );

    React.useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
};
