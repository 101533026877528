import React from "react";

import c from "classnames";
import { FC } from "react";

import Logo from "src/ui/images/Logo";
import SignUpForm from "src/components/forms/SignUpForm";

import s from "./style.module.sass";
import { useTranslation } from "react-i18next";
import { RegisterRequest } from "src/types";
import { Confirmation } from "./Confirmation";

const PageSignUp: FC = () => {
  const { t } = useTranslation();
  const [registerData, setRegisterData] = React.useState<RegisterRequest | null>(null);
  
  const clearRegisterData = React.useCallback(
    () => {
      setRegisterData(null);
    },
    [],
  );
  
  if (registerData !== null) {
    return (
      <Confirmation data={registerData} clearData={clearRegisterData} />
    );
  }

  return (
    <>
      <h1 className={c(s.title)}>{`${t('BTN_CREATE_ACCOUNT')}`}</h1>
      <Logo className={c(s.logo)} />
      <SignUpForm onSubmit={setRegisterData} />
    </>
  );
};

export default PageSignUp;
