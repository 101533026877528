import React from "react";

import { TSocialDraggable, TSocialDraggableList } from "../types";
import Sortable from "sortablejs";
import { ReactSortable, Store } from "react-sortablejs";
import styles from "./style.module.sass";
import { SortableSocialsItem } from "./SortableSocialsItem";
import SocialLinks from "social-links";
import { 
    TPageSocialLink, TPageSocialLinkList, TSocialLinkPositionList
} from "src/types";
import { sortSocials } from "./sort-socials";

type TProps = Readonly<{
    links: TPageSocialLinkList;
    onDelete: (linkId: string) => void;
    onSortLinks: (list: TSocialLinkPositionList) => void;
}>;

type TOnSetList = (
    newState: TSocialDraggableList, 
    sortable: Sortable | null, 
    store: Store
) => void;

const socialLinks = new SocialLinks();
const createSocialItem = (item: TPageSocialLink): TSocialDraggable => {
    return {
        ...item, 
        id: +item.id, 
        social_link_name: socialLinks.detectProfile(item.social_link)
    };
};

const SortableSocials_: React.FC<TProps> = ({
    links,
    onDelete,
    onSortLinks,
}: TProps) => {
    const [list, setList] = React.useState<TSocialDraggableList>(
        sortSocials(links.map(createSocialItem))
    );

    const changeOrder = React.useCallback<TOnSetList>(
        (newState) => {
            if (JSON.stringify(newState) !== JSON.stringify(list)) {
                setList(newState);
                onSortLinks(newState.map((x, index) => ({
                    id: `${x.id}`,
                    link_position: `${index}`
                })));
            }
        },
        [list, onSortLinks],
    );

    return (
        <ReactSortable
            animation={200}
            direction="vertical"
            list={list} 
            setList={changeOrder} 
            className={styles.main}
        >
            {list.map((item) =>
                <SortableSocialsItem 
                    key={`${item.id}`} 
                    {...item} 
                    onDelete={onDelete}
                />
            )}
        </ReactSortable>
    );
};

export const SortableSocials = React.memo(SortableSocials_);
