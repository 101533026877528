import React, { FC } from "react";

import "react-multi-carousel/lib/styles.css";
import { TSliderColorList } from "src/types";
import { Txt } from "src/ui/text";
import styled from "styled-components";
import { Slider } from "../Slider";
import { SliderColorItem } from "./SliderColorItem";
import styles from "./style.module.sass";


type TProps = Readonly<{
  header: string;
  list: TSliderColorList;
  onClick: (value: string) => void;
  activeValue: string;
  width: string;
  height: string;
  itemsInRow: number;
  marginTop: string;
}>;

interface ISliderContainerProps {
  marginTop: string;
}

const SliderContainer = styled.div<ISliderContainerProps>`
    margin-top: ${({ marginTop }) => marginTop};
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
`

const SliderColors_: FC<TProps> = ({
  header,
  list,
  onClick,
  activeValue,
  width,
  height,
  itemsInRow,
  marginTop,
}: TProps) => {
    return (
      <SliderContainer marginTop={marginTop}>
        <Txt textStyle="body_14">{header}</Txt>
        <Slider
          containerClass={styles.slider}
          deviceType="mobile" 
          itemsInRow={itemsInRow}
        >
          {list.map((item) =>
              <SliderColorItem 
                {...item} 
                key={item.value}
                isActive={item.value === activeValue} 
                onClick={onClick}
                width={width}
                height={height}
              />
          )}
        </Slider>  
      </SliderContainer>
    );
};

export const SliderColors = React.memo(SliderColors_);
