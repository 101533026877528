import React from "react";

import { SocialIcon } from "react-social-icons";
import { BackgroundColors, Colors } from "src/constants";
import { TPageSocialLinkList } from "src/types";

type TProps = Readonly<{
    list: TPageSocialLinkList;
}>;

const style = {
    width: '34px', 
    height: '34px',
    borderRadius: '120px',
    marginRight: '5px',
    marginTop: '5px'
}

const SocialIcons_: React.FC<TProps> = ({
    list,
}: TProps) => {
    return (
        <div className="d-flex flex-wrap justify-content-evenly">
            {list.map((item) =>
                <SocialIcon 
                    key={item.id}
                    bgColor={Colors.sysBlue1} 
                    fgColor={BackgroundColors.bgWhite}
                    style={style} 
                    url={item.social_link} 
                />
            )}
        </div>
    );
};

export const SocialIcons = React.memo(SocialIcons_);
