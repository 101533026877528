import React, { FC } from "react";

import styled from "styled-components";
import { defaultOptions, TextOptions, TTextOptions } from "./constants";
import { TTextStyle } from "src/types";
import { TabletScreenWidth } from "src/constants";

type TTextInnerProps = Readonly<{
    color?: string;
    marginTop?: string;
    marginLeft?: string;
    textAlign?: 'start' | 'end' | 'center';
    position?: 'relative' | 'absolute';
    top?: string;
    left?: string;
    transition?: string;
    userSelect?: 'none' | 'auto';
    pointerEvents?: 'none' | 'auto';
    padding?: string;
    backgroundColor?: string;
    right?: string;
    bottom?: string;
    display?: 'flex' | 'block';
    fontFamily?: string;
    flexWrap?: 'wrap';
    marginBottom?: string;
    maxWidth?: string; // cannot be set as "input" prop
    lgMaxWidth?: string;
    minHeight?: string;
    wordBreak?: 'break-word' | undefined;
}>;

type TProps = TTextInnerProps & Readonly<{
    children: React.ReactNode;
    textStyle?: TTextStyle;
    className?: string;
}>;

const TextInner = styled.div<TTextInnerProps & TTextOptions>`
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    line-height: ${({ lineHeight }) => lineHeight};
    text-transform: ${({ textTransform }) => textTransform || defaultOptions.textTransform};
    color: ${({ color }) => color || 'unset'};
    margin-top: ${({ marginTop }) => marginTop || 'unset'};
    margin-left: ${({ marginLeft }) => marginLeft || 'unset'};
    text-align: ${({ textAlign }) => textAlign || 'unset'};
    position: ${({ position }) => position || 'unset'};
    top: ${({ top }) => top || 'unset'};
    left: ${({ left }) => left || 'unset'};
    right: ${({ right }) => right || 'unset'};
    bottom: ${({ bottom }) => bottom || 'unset'};
    transition: ${({ transition }) => transition || 'unset'};
    user-select: ${({ userSelect }) => userSelect || 'unset'};
    pointer-events: ${({ pointerEvents }) => pointerEvents || 'unset'};
    padding: ${({ padding }) => padding || 'unset'};
    background-color: ${({ backgroundColor }) => backgroundColor || 'unset'};
    display: ${({ display }) => display || undefined};
    font-family: ${({ fontFamily }) => fontFamily || undefined};
    flex-wrap: ${({ flexWrap }) => flexWrap || undefined};
    margin-bottom: ${({ marginBottom }) => marginBottom || undefined};
    max-width: ${({ maxWidth }) => maxWidth || undefined};
    min-height: ${({ minHeight }) => minHeight || undefined};
    word-break: ${({ wordBreak }) => wordBreak || undefined};
    @media (min-width: ${TabletScreenWidth}px) {
        max-width:  ${({ lgMaxWidth }) => lgMaxWidth || undefined};
    }
`

const Txt_: FC<TProps> = (props: TProps) => {
    const {textStyle, children} = props;

    const opts = textStyle ? TextOptions[textStyle] : defaultOptions;
    return (
        <TextInner 
            {...opts} 
            {...props} 
            className={props.className || undefined}
        >
            {children}
        </TextInner>
    );
};

export const Txt = React.memo(Txt_);
