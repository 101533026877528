import React from "react";

import { TIconList, TPublicPage } from "src/types";
import styled from "styled-components";
import imgEdit from 'src/assets/img/edit.svg';
import { BlockContainer } from "src/ui/containers/BlockContainer";
import { Head } from "./components/Head";
import { Body } from "./components/Body";

type TProps = Readonly<{
    page: TPublicPage;
    isPublicPage?: boolean;
    padding?: string;
    coverHeight?: string;
    marginTop?: string;
    isTransparent?: boolean;
    blockBodyPadding?: string;
    onOpenEdit?: () => void;
    onClosePreview?: () => void;
}>;

const PageContainer = styled.div`
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -67px;
`

const DefaultBlock_: React.FC<TProps> = ({
    page,
    isPublicPage = false,
    padding,
    coverHeight,
    marginTop,
    isTransparent,
    blockBodyPadding,
    onOpenEdit,
    onClosePreview
}: TProps) => {
    const iconButtons = React.useMemo<TIconList>(
        () => ([
            { src: imgEdit, onClick: onOpenEdit || undefined}
        ]),
        [onOpenEdit],
    );

    return (
        <BlockContainer
            isTextCentered 
            icons={isPublicPage ? undefined : iconButtons}  
            color={page.page_font_color || undefined}
            marginTop={marginTop || "15px"}
            fontFamily={page.page_font || undefined}
            padding={padding}
            isTransparent={isTransparent}
        >
            <PageContainer>
                <Head 
                    cover={page.page_cover}
                    coverColor={page.page_cover_color}
                    pageTagline={page.page_tagline}
                    minHeight={coverHeight} 
                    onClosePreview={onClosePreview}
                />
                <Body 
                    pagePhoto={page.page_photo}
                    userName={page.username}
                    pageName={page.page_name}
                    pageTitle={page.page_title}
                    pageDescription={page.page_description}
                    socialLinks={page._embedded.social_link}
                    padding={blockBodyPadding}
                />
            </PageContainer>
        </BlockContainer>
    );
};

export const DefaultBlock = React.memo(DefaultBlock_);
