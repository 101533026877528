import { FC, useMemo } from "react";

import s from "./style.module.sass"

type Props = {
  className?: string,
  src: string,
  alt: string
};

const LocalImage: FC<Props> = ({
  className, src, alt
}) => {
  const absoluteSrc = useMemo(() => {
    return require(`src/assets/img/${src}`)
  }, [src]);

  return (
    <picture className={className}>
      <img
        className={s.img}
        src={absoluteSrc}
        alt={alt}
      />
    </picture>
  );
};

export default LocalImage;