import React, { FC } from "react";

import { ButtonColors, TextColors } from "src/constants";
import { IPageBlockBase, ImageType, TIconList } from "src/types";
import { ImageList } from "src/ui/images/ImageList";
import { Txt } from "src/ui/text";
import styled from "styled-components";
import imgDrag from 'src/assets/img/drag.svg';
import imgEdit from 'src/assets/img/edit.svg';
import { BlockContainer } from "src/ui/containers/BlockContainer";
import { useFlag } from "src/hooks/useFlag";

interface TItem extends IPageBlockBase {
    id: number | string;
}

type TProps = Readonly<{
    item: TItem;
    isTransparent?: boolean;
    color?: string;
    fontFamily?: string;
    carouselOnOver?: () => void;
    carouselOnLeave?: () => void;
    onEdit?: (id: number) => void;
}>;

interface IButtonProps {
    fontFamily?: string;
}

const Button = styled.button<IButtonProps>`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
    height: 51px;
    border: none;
    border-radius: 12px;
    background-color: ${ButtonColors.disabled};
    transition: 0.3s ease background-color;
    user-select: none;
    color: ${TextColors.dark4};
    margin: 30px 10px 0px;
    font-family: ${({ fontFamily }) => fontFamily || undefined};
`

const SimpleBlock_: FC<TProps> = ({
    item,
    isTransparent = false,
    color,
    fontFamily,
    onEdit,
    carouselOnOver,
    carouselOnLeave,
}: TProps) => {
    const [isBlockClosed, , ,toggleBlock] = useFlag(); 
    const {
        block_title, block_description, block_url, block_url_text,
        image_type, images, id,
    } = item;

    const edit = React.useCallback(
        () => {
            if (!onEdit) {
                return;
            }
            onEdit(typeof id === 'string' ? +id : id);
        },
        [id, onEdit],
    );

    const onLongClick = React.useCallback(
        () => {
            toggleBlock();
        },
        [toggleBlock]
    );

    const iconButtons = React.useMemo<TIconList>(
        () => ([
            { src: imgDrag, onLongClick },
            { src: imgEdit, onClick: edit}
        ]),
        [edit, onLongClick],
    );

    const openLink = React.useCallback(
        () => {
            window.open(block_url, '_blank', 'noopener,noreferrer');
        },
        [block_url],
    );

    return (
            <BlockContainer 
                isTextCentered 
                icons={onEdit ? iconButtons : undefined} 
                header={block_title} 
                color={color || undefined}
                fontFamily={fontFamily || undefined}
                isTransparent={isTransparent}
                isClosed={isBlockClosed}
                className={isBlockClosed ? "shadow" : undefined}
            >
                <Txt textStyle='body_16'>{block_description}</Txt>
                <ImageList 
                    items={images}
                    imageOption={image_type as ImageType}
                    carouselOnOver={image_type === ImageType.Carousel ? carouselOnOver : undefined}
                    carouselOnLeave={image_type === ImageType.Carousel ? carouselOnLeave : undefined}
                    hasMargin={
                        ((block_description || "").length > 0) || 
                        ((block_title || "").length > 0)
                    }
                />
                {!!block_url && (
                    <Button fontFamily={fontFamily} onClick={openLink}>
                        <Txt textStyle="h3">
                            {block_url_text}
                        </Txt>
                    </Button>
                )}
            </BlockContainer>
    );
};

export const SimpleBlock = React.memo(SimpleBlock_);
