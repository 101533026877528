import c from "classnames";
import React, { FC } from "react";
import { Controller } from "react-hook-form";

import Input from "src/ui/inputs/Input";
import MainButton from "src/ui/buttons/MainButton";

import useSignIn from "./hook";
import s from "./style.module.sass";
import { useTranslation } from "react-i18next";
import { LinkButton } from "src/ui/buttons/LinkButton";
import { useNavigate } from "react-router-dom";

const SignInForm: FC = () => {

  const {
    errors,
    control,
    isLoading,
    isDisabled,
    onSubmit,
  } = useSignIn();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openSignUp = React.useCallback(
    () => {
      navigate("/signup");
    },
    [navigate],
  );

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
    >
      <Controller
        name="username"
        control={control}
        render={({ field }) =>
          <Input
            type="email"
            name={field.name}
            value={field.value}
            error={errors.username}
            label={t('LOGIN')}
            className={c(s.input)}
            onBlur={field.onBlur}
            onChange={field.onChange}
          />
        }
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) =>
          <Input
            type="password"
            name={field.name}
            value={field.value}
            error={errors.password}
            label={t('TITLE_PASSWORD')}
            className={c(s.input)}
            onBlur={field.onBlur}
            onChange={field.onChange}
          />
        }
      />
      <MainButton
        className={c(s.button)}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onClick={onSubmit}
      >
        {t('BTN_SIGN_IN')}
      </MainButton>
      <LinkButton className={c(s.button)} label={t('BTN_CREATE_ACCOUNT')} onClick={openSignUp} />
    </form>

  )
};

export default SignInForm;
