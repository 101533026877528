import { TSocialDraggable, TSocialDraggableList } from "../types";

const compareFn = (a: TSocialDraggable, b: TSocialDraggable): number => {
    if (+a.link_position < +b.link_position) {
        return -1;
      }
      if (+a.link_position > +b.link_position) {
        return 1;
      }
      return 0;
};

export const sortSocials = (list: TSocialDraggableList): TSocialDraggableList => {
    return list.sort(compareFn);
};
