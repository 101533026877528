import React from "react";

import { TRegisteredCard, TSelectList } from "src/types";
import { Card } from "../Card";
import styled from "styled-components";
import { Colors, ScreenSizes, TextColors } from "src/constants";
import { Txt } from "src/ui/text";
import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, object, string } from "yup";
import { Controller, useForm } from "react-hook-form";
import { Selector } from "src/ui/selector";
import Switch from "react-switch";
import Input from "src/ui/inputs/Input";
import { Loader } from "src/ui/loader";
import { TLinkCardCb, TLinkCardToUrlCb } from "../types";
import { useTranslation } from "react-i18next";
import { StandardModal } from "src/components/modals/StandardModal";

type TProps = Readonly<{
    isLoading: boolean;
    item: TRegisteredCard;
    pageOptions: TSelectList;
    onLinkCardToUrl: TLinkCardToUrlCb;
    linkCardToProfileAndPage: TLinkCardCb;
    loadCard: (cardKey: number) => void;
    onClose: () => void;
}>;

const CardContainer = styled.div`
    margin-bottom: 30px;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.05));
    @media (min-width: ${ScreenSizes.xsm}) {
        width: 350px;
    }
`

const SwitchContainer = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 48px;
    align-items: center;
`
const InputContainer = styled.div`
    display: flex;
`
const LoaderPlaceholder = styled.div`
    width: 44px;
`
const getPageId = (pages: TSelectList, url: string | null): string | null=> {
    if (typeof url !== 'string') {
        return null;
    }
    return pages.find(x => x.label === url)?.value || null;
};

const getPageUrl = (pages: TSelectList, id: string | null): string | null=> {
    if (typeof id !== 'string') {
        return null;
    }
    return pages.find(x => x.value === id)?.label || null;
};

type TForm = Readonly<{
    isActive: boolean;
    pageId: string | null;
    cardKey: string;
}>;
  
const PhysicalCard_: React.FC<TProps> = ({
    isLoading,
    item,
    pageOptions,
    onLinkCardToUrl,
    linkCardToProfileAndPage,
    loadCard,
    onClose,
}: TProps) => {
    const { t } = useTranslation();
    
    const {page_address, users_id, card_type, id} = item;
    const isActiveInitial: boolean = !!(users_id && users_id.length > 0);
    const initialPageId: string | null = getPageId(pageOptions, page_address || null);

    const {
        control,
        formState,
        setError,
        reset,
        getValues,
        handleSubmit,
      } = useForm<TForm>({
        mode: "all",
        defaultValues: {
            isActive: isActiveInitial,
            pageId: initialPageId,
            cardKey: item.key,
        },
        resolver: yupResolver(object({
            isActive: boolean().isTrue(),
            cardKey: string().required()
        })),
    });

    const noCardKey = item.key.length === 0;

    const linkCardToUrl = React.useCallback<(cardKey: string, pageId: string | null) => void>(
        (cardKey, pageId) => {
            if (!id) {
                setError("cardKey", {message: t('CARD_ERROR_NOT_ACTIVE') || "Card should be activated first"});
                return;
            }

            if (item.page_address && item.page_address.length > 0) {
                console.log('Card already linked to some page');
                return;
            }
            if (!pageId) {
                return;
            }

            const url = getPageUrl(pageOptions, pageId);

            if (!url) {
                console.log('Page is no longer available for linking');
                return;
            }
            onLinkCardToUrl({
                cardKey: cardKey, 
                cardId: id,
                url,
                pageId: pageId || undefined
            })
        },
        [id, item.page_address, onLinkCardToUrl, pageOptions, setError, t]
    ) 
    const submit = React.useCallback(
        () => {
            if (!formState.isValid || typeof card_type === 'undefined') {
                return;
            }

            if(isActiveInitial) {
                handleSubmit(data => linkCardToUrl(data.cardKey, data.pageId))();
            } else {
                handleSubmit(data => linkCardToProfileAndPage({
                    cardKey: data.cardKey, 
                    type: card_type,
                    url: getPageUrl(pageOptions, data.pageId) || undefined,
                    pageId: data.pageId || undefined
                }))();
            }
            onClose();
        },
        [card_type, formState.isValid, handleSubmit, isActiveInitial, linkCardToProfileAndPage, linkCardToUrl, onClose, pageOptions],
    );

    React.useEffect(
        () => {
            reset({
                isActive: isActiveInitial,
                pageId: initialPageId,
                cardKey: item.key.length > 0 ? item.key : getValues('cardKey'),
            });
        },
        [getValues, initialPageId, isActiveInitial, item.key, reset]
    );

    return (
        <StandardModal
            header={`Card ${item.key}`}
            headerTextStyle="h2"
            isSubmitDisabled={!formState.isValid}
            onSubmit={submit} 
            onClose={onClose}
        >
            <CardContainer>
                <Card gap={0} item={{...item, key: item.key.length > 0 ? item.key : 'Unknown card'}} />
            </CardContainer>
            <form onSubmit={(e) => e.preventDefault()}>
                <Controller
                    name="cardKey"
                    control={control}
                    render={({ field }) =>
                        <InputContainer>
                            <Input
                                type="text"
                                error={
                                    field.value.length > 0 && noCardKey 
                                    ? t('CARD_ERROR_DOESNT_EXIST') || "Card not found" 
                                    : undefined
                                }
                                label="Card id" // intentional, Card key
                                name={field.name}
                                value={field.value}
                                onBlur={field.onBlur}
                                onChange={e => {
                                    field.onChange(e.target.value);
                                    loadCard(+e.target.value);
                                }} 
                            />
                            {isLoading ? (
                                <Loader noLabel width="44px" />
                            ) : (
                                <LoaderPlaceholder />
                            )}
                        </InputContainer>
                    }
                />
                <Controller
                    name="isActive"
                    control={control}
                    render={({ field }) =>
                        <SwitchContainer>
                            <Switch 
                                onColor={Colors.sysBlue1} 
                                checked={field.value} 
                                disabled={noCardKey || isActiveInitial} // can't change initial value of "active" to "inactive"
                                onChange={field.onChange} 
                                handleDiameter={24}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                height={28}
                                width={44}
                            />
                            <Txt 
                                color={(noCardKey || isActiveInitial) ? TextColors.dark4 : undefined} 
                                textStyle="body_secondary" 
                                marginLeft="12px"
                            >
                                {t('CARD_STATUS')}
                            </Txt>
                        </SwitchContainer>
                    }
                />
                <Controller
                    name="pageId"
                    control={control}
                    render={({ field }) =>
                        <Selector
                            hasDefaultOption
                            label="URL"
                            selectedValue={field.value ?? ""}
                            list={pageOptions}
                            onSelect={field.onChange}
                            isDisabled={
                                !formState.isValid || 
                                !!(item.page_address && item.page_address.length > 0)
                            }
                        />
                    }
                />
            </form>
        </StandardModal>
    )
};

export const PhysicalCard = React.memo(PhysicalCard_);
