import { TSelectList } from "src/types";

export const FontOptions: TSelectList = [
    {
        value: "'Inter', sans-serif",
        label: "Inter",
        style: {
            fontFamily: "'Inter', sans-serif",
        }
    },
    {
        value: "'Roboto', sans-serif",
        label: "Roboto",
        style: {
            fontFamily: "'Roboto', sans-serif",
        }
    },
    {
        value: "'Open Sans', sans-serif",
        label: "Open Sans",
        style: {
            fontFamily: "'Open Sans', sans-serif",
        }
    },
    {
        value: "'Montserrat', sans-serif",
        label: "Montserrat",
        style: {
            fontFamily: "'Montserrat', sans-serif",
        }
    },
    {
        value: "'Raleway', sans-serif",
        label: "Raleway",
        style: {
            fontFamily: "'Raleway', sans-serif",
        }
    },
    {
        value: "'Nunito', sans-serif",
        label: "Nunito",
        style: {
            fontFamily: "'Nunito', sans-serif",
        }
    },
    {
        value: "'Playfair Display', serif",
        label: "Playfair Display",
        style: {
            fontFamily: "'Playfair Display', serif",
        }
    },
    {
        value: "'Cormorant', serif",
        label: "Cormorant",
        style: {
            fontFamily: "'Cormorant', serif",
        }
    },
    {
        value: "'PT Serif', serif",
        label: "PT Serif",
        style: {
            fontFamily: "'PT Serif', serif",
        }
    },
    {
        value: "'Bitter', serif",
        label: "Bitter",
        style: {
            fontFamily: "'Bitter', serif",
        }
    },
    {
        value: "'Roboto Mono', monospace",
        label: "Roboto Mono",
        style: {
            fontFamily: "'Roboto Mono', monospace",
        }
    },
    {
        value: "'Ubuntu', sans-serif",
        label: "Ubuntu",
        style: {
            fontFamily: "'Ubuntu', sans-serif",
        }
    },
];
