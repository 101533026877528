import { TTextStyle } from "src/types";

export type TTextOptions = Readonly<{
    fontSize: string;
    fontWeight: string;
    lineHeight: string;
    textTransform?: string;
}>;

export const defaultOptions: TTextOptions = {
    fontSize: 'unset',
    fontWeight: 'unset',
    lineHeight: 'unset',
    textTransform: 'unset',
};

export const TextOptions: Readonly<Record<TTextStyle, TTextOptions>> = {
    h0: {
        fontSize: '34px',
        fontWeight: '700',
        lineHeight: '36px',
    },
    h1: {
        fontSize: '24px',
        fontWeight: '700',
        lineHeight: '36px',
    },
    h2: {
        fontSize: '18px',
        fontWeight: '600',
        lineHeight: '27px',
    },
    h3: {
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
    },
    body_16: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
    },
    uppercase: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '20px',
        textTransform: 'uppercase'
    },
    body_14: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '24px',
    },
    body_12: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '18px',
    },
    body_secondary: {
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '20px',
    },
    title_lg: {
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '29px',
    },
    title_xlg: {
        fontSize: '30px',
        fontWeight: '600',
        lineHeight: '35px',
    },
    title_md: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '22px'
    },
    body_semibold: {
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '24px',
    },
    help_text: {
        fontSize: '10px',
        fontWeight: '500',
        lineHeight: '16px',
    },
    page_address: {
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '16px',
    }
};
