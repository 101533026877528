import axios, { AxiosInstance, AxiosResponse } from "axios";

export namespace APIClient {
  const baseURL: string = "https://api.ntmy.space";
  // const baseURL_dev: string = "http://snail-api.test";
  const timeout: number = 60_000;

  const defaultClient: AxiosInstance = axios.create({
    baseURL,
    timeout,
    headers: {
      "Content-Type": "application/json",
    }
  });

  export type Method =
    | "GET"
    | "POST"
    | "PUT"
    | "DELETE"
    | "PATCH";

  export type APIRequest<T> = {
    method: Method,
    url: string,
    data?: T,
    headers?: {
      [key: string]: string | number | undefined
    }
  };

  export function request<Request, Response>(
    requestModel: APIRequest<Request>
  ): {
    request: () => Promise<AxiosResponse<Response>>,
    abort: () => void,
  } {
    const controller = new AbortController();
    const abort = controller.abort;

    const request = async () => {
      return await defaultClient.request<Response>({
        ...requestModel
      });
    };

    return {
      request,
      abort,
    };
  }
};
