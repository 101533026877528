import React, { FC } from "react";

import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { ImageUploader } from "src/components/image-uploader";
import { IPageBlock, ImageType, TBlockImageList, TSliderList } from "src/types";
import Input from "src/ui/inputs/Input";
import { SliderButtons } from "src/ui/slider/SliderButtons";
import styles from "./style.module.sass";
import { urlRegExp } from "src/validators";
import { TOnAddBlock, TOnUpdateBlock } from "../types";
import { Txt } from "src/ui/text";
import { TextColors } from "src/constants";
import styled from "styled-components";
import { StandardModal } from "src/components/modals/StandardModal";

type TProps = Readonly<{
    onDelete: (blockId: string) => void;
    onAdd: TOnAddBlock;
    onUpdate: TOnUpdateBlock;
    onClose: () => void;
    block: IPageBlock | null;
}>;

type TImages = {
    files: Array<File>,
    removedIds: Array<string>,
    existingImages: TBlockImageList; 
};

type TForm = Readonly<{
    title: string;
    description: string;
    url: string;
    linkText: string;
    images: TImages;
}>;

const SliderContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
`
const isImageFieldEmpty = (images: TImages, originalLength: number): boolean => {
    return !(images && images.files && images.files.length > 0) && 
    !(images && images.existingImages && images.existingImages.length > 0) &&
    !(images && images.removedIds && images.removedIds.length > 0 && originalLength !== images.removedIds.length);
};

const AddSimpleBlock_: FC<TProps> = ({
    onDelete,
    onAdd,
    onUpdate,
    onClose,
    block,
}: TProps) => {
    const { t } = useTranslation();

    const {
        control,
        formState,
        clearErrors,
        reset,
        resetField,
        handleSubmit,
      } = useForm<TForm>({
        mode: "all",
        defaultValues: {
            title: block?.block_title ?? "",
            description: block?.block_description ?? "",
            url: block?.block_url ?? "",
            linkText: block?.block_url_text ?? "",
            images: {
                files: [],
                removedIds: [],
                existingImages: block ? block.images : [],
            },
        },
        resolver: yupResolver(object().shape({
            title: string()
            .when(["images", "description", "url"], {
                is: (images: any, description: any, url: any) => {
                    const originalLength = block?.images.length || 0;
                    return isImageFieldEmpty(images, originalLength) && !description && !url
                },
                then: string().required('At least one field required')
            }),
            description: string()
            .when(["images", "title", "url"], {
                is: (images: any, title: any, url: any) => {
                    const originalLength = block?.images.length || 0;
                    return isImageFieldEmpty(images, originalLength) && !title && !url
                },
                then: string().required('At least one field required')
            }),
            url: string()
            .when("linkText", {
                is: (linkText: any) => (linkText ?? "").length > 0,
                then: string().required('Required field').matches(urlRegExp, 'URL is not valid'),
                otherwise: string().optional()
            })
            .when("url", {
                is: (url: any) => (url ?? "").length > 0,
                then: string().optional().matches(urlRegExp, 'URL is not valid'),
                otherwise: string().optional()
            }),
            linkText: string().when("url", (url) =>
                (url ?? "").length > 0 ? string().required('Required field') : string().optional()
            ),
        }, [
            ['linkText', 'url'],
            ['url', 'url'],
            ['url', 'linkText'],
            ['title', 'url'],
            ['title', 'description'],
            ['title', 'images'],
        ])),
      });

    const [imageOption, setImageOption] = React.useState<ImageType>(block 
        ? (block.image_type as ImageType) 
        : ImageType.NoImage
    );

    const isEdit: boolean = !!block;

    const imgOptions = React.useMemo<TSliderList>(
        () => ([
                {
                    label: t('BTN_NO_IMAGE'),
                    value: ImageType.NoImage,
                },
                {
                    label: t('BTN_SMALL'),
                    value: ImageType.Small,
                },
                {
                    label: t('BTN_MEDIUM'),
                    value: ImageType.Medium,
                },
                {
                    label: t('BTN_BIG'),
                    value: ImageType.Big,
                },
                {
                    label: t('BTN_CAROUSEL'),
                    value: ImageType.Carousel,
                }
            ]),
        [t],
    );

    const changeImgOption = React.useCallback<(option: string) => void>(
        option => {
            if(option === ImageType.NoImage) {
                resetField("images");
            }
            setImageOption(option as ImageType);
        },
        [resetField],
    );

    const close = React.useCallback(
        () => {
            reset();
            onClose();
        },
        [onClose, reset],
    );

    const deleteBlock = React.useCallback(
        () => {
            if (!block) {
                return;
            }
            onDelete(block.block_id);
            close();
        },
        [block, close, onDelete],
    );

    const add = React.useCallback(
        () => {
            handleSubmit(data => onAdd({
                title: data.title,
                description: data.description,
                url: data.url,
                urlText: data.linkText,
                imageOption,
                images: data.images.files
            }))();
            close();
        },
        [close, handleSubmit, imageOption, onAdd],
    );

    const update = React.useCallback(
        () => {
            if (!block) {
                return;
            }

            handleSubmit(data => onUpdate({
                title: data.title,
                description: data.description,
                url: data.url,
                urlText: data.linkText,
                imageOption,
                images: data.images.files,
                blockId: block?.block_id,
                position: block?.block_position,
                removedImagesIds: data.images.removedIds,
            }))();
            close();
        },
        [block, close, handleSubmit, imageOption, onUpdate],
    );
    React.useEffect(
        () => {
            if (formState.isValid) {
                clearErrors();
            }
        },
        [clearErrors, formState.isValid],
    );

    return (
        <StandardModal 
            header={`${isEdit ? t('TITLE_EDIT_BLOCK') : t('BTN_ADD_BLOCK')}`} 
            isSubmitDisabled={!formState.isValid} 
            onSubmit={isEdit ? update : add} 
            onCancel={isEdit ? deleteBlock : close}
            onClose={close}
            headerTextStyle="h1"
            headerClassName="ms-auto"
        >
            <form onSubmit={(e) => e.preventDefault()}>
                <Controller
                    name="title"
                    control={control}
                    render={({ field }) =>
                        <Input
                            type="text"
                            error={formState.errors.title?.message}
                            label={`${t('TITLE')}`}
                            className={styles.input}
                            name={field.name}
                            value={field.value}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                        />
                    }
                />
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) =>
                        <Input
                            type="text"
                            error={formState.errors.description?.message}
                            label={`${t('DESCRIPTION')}`}
                            className={styles.input}
                            name={field.name}
                            value={field.value}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                        />
                    }
                />
                <Controller
                    name="url"
                    control={control}
                    render={({ field }) =>
                        <Input
                            type="text"
                            error={formState.errors.url?.message}
                            label={`${t('URL')}`}
                            className={styles.input}
                            name={field.name}
                            value={field.value}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                        />
                    }
                />
                <Controller
                    name="linkText"
                    control={control}
                    render={({ field }) =>
                        <Input
                            type="text"
                            error={formState.errors.linkText?.message}
                            label={`${t('TEXT_ON_LINK')}`}
                            className={styles.input}
                            name={field.name}
                            value={field.value}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                        />
                    }
                />
                <Controller
                    name="images"
                    control={control}
                    render={({ field }) =>
                        <>
                            <SliderContainer>
                                <Txt textStyle="body_12" color={TextColors.dark3}>{t('IMAGE')}</Txt>
                                <SliderButtons 
                                    className={styles.slider} 
                                    list={imgOptions} 
                                    activeValue={imageOption}
                                    onClick={changeImgOption}
                                /> 
                            </SliderContainer>
                            {imageOption !== ImageType.NoImage && (
                                <ImageUploader 
                                    imageOption={imageOption} 
                                    imageFiles={field.value.files} 
                                    onSetImageFiles={files => 
                                        field.onChange({...field.value, files })
                                    } 
                                    existingImages={field.value.existingImages}
                                    onRemoveExistingImage={id => 
                                        field.onChange({
                                            ...field.value, 
                                            removedIds: [...field.value.removedIds, id],
                                            existingImages: field.value.existingImages.filter(x => x.id !== id)
                                         })
                                    }
                                />
                            )}
                        </>
                    }
                />
            </form>     
        </StandardModal>
    );
};

export const AddSimpleBlock = React.memo(AddSimpleBlock_);
