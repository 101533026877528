import React, { CSSProperties } from "react";

import { useFlag } from "src/hooks/useFlag";
import styled from "styled-components";
import imgDrag from 'src/assets/img/drag.svg';
import { Txt } from "src/ui/text";
import { Colors } from "src/constants";
import { SocialIcon } from "react-social-icons";
import { TSocialDraggable } from "../types";

type TProps = TSocialDraggable & Readonly<{
    onDelete: (linkId: string) => void;
}>;

const SocialIconItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`

interface IIconProps {
    src: string;
    size: number;
    hasMargin: boolean;
}

const Icon = styled.div<IIconProps>`
    background-image: ${({ src }) => `url(${src})`};
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    padding: 2px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-size: contain;
    margin-right: ${({ hasMargin }) => hasMargin ? '10px' : 'unset'};
`

const CloseButton = styled.button`
    border-radius: 30px;
    padding: 2px 7px;
    color: grey;
    transform: rotate(45deg);
    font-size: 20px;
    background-color: transparent;
`
const Container = styled.button`
    flex-direction: row;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: transparent;
`

const SocialIconStyle = {
    width: '30px', 
    height: '30px', 
    marginRight: '10px', 
    pointerEvents: 'none'
};


const SortableSocialsItem_: React.FC<TProps> = ({
    id,
    social_link,
    social_link_name,
    onDelete,
}: TProps) => {
    const [isCloseVisible, , , toggleCloseVisibility] = useFlag();

    const deleteLink = React.useCallback(
        () => {
            onDelete(`${id}`);
        },
        [id, onDelete],
    );

    return (
        <SocialIconItem>
            <Container onClick={toggleCloseVisibility}>
                <Icon size={17} src={imgDrag} hasMargin={true} />
                <SocialIcon 
                    bgColor={Colors.sysBlue1} 
                    style={SocialIconStyle as CSSProperties} 
                    url={social_link} 
                />
                <Txt textStyle="body_16">{social_link_name}</Txt>
            </Container>
            {isCloseVisible && (
                <CloseButton onClick={deleteLink}>+</CloseButton>
            )}
        </SocialIconItem>
    );
};

export const SortableSocialsItem = React.memo(SortableSocialsItem_);
