import React, { FC } from "react";

import { TImageItem } from "src/types";
import styled from "styled-components";
import closeImage from 'src/assets/img/closeImage.svg';

type TButtonProps = Readonly<{
    marginRight: string;
    marginTop: string;
    imgUrl: string;
    size: number;
    isCarousel: boolean;
}>;

type TProps = Readonly<{
    item: TImageItem;
    size: number;
    numberInRow: number;
    index: number;
    gap: number;
    onClick?: () => void;
    onImageRemove?: (id: string) => void;
    imageRef?: React.RefObject<HTMLDivElement> | null;
    isCarousel?: boolean;
}>;

const ImageInner = styled.button<TButtonProps>`
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    border-radius: 12px;
    background-image: ${({ imgUrl }) => `url(${imgUrl})`};
    height: ${({ size }) => `${size}px`};
    width: ${({ size, isCarousel }) => isCarousel ? '100%' : `${size}px`};
    margin-right: ${({ marginRight }) => marginRight};
    margin-top: ${({ marginTop }) => marginTop};
`

interface IIconProps {
    src: string;
    marginRight: string;
    marginTop: string;
}

const Icon = styled.button<IIconProps>`
    background-image: ${({ src }) => `url(${src})`};
    margin-right: ${({ marginRight }) => marginRight};
    margin-top: ${({ marginTop }) => marginTop};
    width: 25px;
    height: 25px;
    right: 5px;
    top: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-size: contain;
`

const ImageContainer_: FC<TProps> = ({
    numberInRow,
    index,
    item,
    size,
    gap,
    onClick,
    onImageRemove,
    imageRef,
    isCarousel = false,
}: TProps) => {
    const rowNumber: number = Math.ceil((index + 1)/numberInRow);

    const marginRightValue = React.useMemo<string>(
        () => {
            const lastItemAt = (rowNumber * numberInRow) - 1;
            if (index === lastItemAt) {
                return 'unset';
            }
            return `${gap}px`;
        },
        [gap, index, numberInRow, rowNumber],
    );

    const marginTopValue = React.useMemo<string>(
        () => {
            if (isCarousel || rowNumber === 1) {
                return 'unset';
            }
            return `${gap}px`;
        },
        [gap, isCarousel, rowNumber],
    );

    const removeImage = React.useCallback(
        () => {
            if (!onImageRemove) {
                return;
            }
            onImageRemove(item.id);
        },
        [item.id, onImageRemove],
    );

    const closeMargin: string = isCarousel ? 'unset' : marginRightValue;

    return (
        <div className="position-relative" ref={imageRef}>
            <ImageInner 
                isCarousel={isCarousel}
                imgUrl={item.block_image_url} 
                size={size} 
                marginRight={isCarousel ? 'unset' : marginRightValue}
                marginTop={isCarousel ? 'unset' : marginTopValue}
                onClick={onClick}
            />
            {!!onImageRemove && (
                <Icon 
                    className="position-absolute" 
                    marginTop={marginTopValue} 
                    marginRight={closeMargin}
                    src={closeImage} 
                    onClick={removeImage} 
                />
            )}
        </div>
    );
};

export const ImageContainer = React.memo(ImageContainer_);
