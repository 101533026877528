import React from "react";

import defaultAvatar from 'src/assets/img/avatars.svg';
import { useUploader } from "./useUploader";
import styled from "styled-components";
import addImageBlue from 'src/assets/img/add-image-blue.svg';


type TProps = Readonly<{
    url: string | null;
    imageFile: File | null;
    onSetImageFile: (val: File) => void;
}>;

interface IIconProps {
    src: string;
}

const Icon = styled.button<IIconProps>`
    background-image: ${({ src }) => `url(${src})`};
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-size: contain;
`

interface IImageProps {
    imgUrl: string;
}

const Image = styled.div<IImageProps>`
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: ${({ imgUrl }) => `url(${imgUrl})`};
`

const ImageContainer = styled.div`
    height: 120px;
    width: 120px;
`

const PhotoUploader_: React.FC<TProps> = ({
    url,
    imageFile = null,
    onSetImageFile,
}: TProps) => {
    const { fileInputRef, imageURL, forwardClickToInput, onImageChange } = useUploader({
        url, imageFile, defaultImage: defaultAvatar, onSetImageFile
    });

    return (
        <div className="mt-6 mb-8">
            <input
                hidden
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={onImageChange}
            />
            <ImageContainer className="position-relative m-auto">
                <Image
                    imgUrl={imageURL}
                    className="m-auto rounded-circle w-100 h-100"
                />
                <Icon 
                    className="position-absolute end-0 bottom-0 mb-1 me-n3" 
                    src={addImageBlue} 
                    onClick={forwardClickToInput} 
                />
            </ImageContainer>
        </div>
    );
};

export const PhotoUploader = React.memo(PhotoUploader_);
