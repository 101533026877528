import { FC } from "react";

import Router from "src/router";
import { useWindowResize } from "src/hooks/useWindowResize";
import { windowResizeHandler } from "src/utils/windowResizeHandler";

const App: FC = () => {
  useWindowResize(windowResizeHandler);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
