import { TSocialLinkPositionList } from "src/types";
import { APIClient } from "../../APIClient";
import { getAccessToken } from "../utils/get-access-token";
import { TAddSocialLinkRequest, TAddSocialLinkResponse } from "./types";

export namespace SocialLinkService {
    export function deleteSocialLink(linkId: string) {
        const token: string = getAccessToken();
    
        return APIClient.request<
            {},
            {}
        >({
            method: "DELETE",
            url: `/cm/pages_social_link/${linkId}`,
            headers: {
                "Content-Type": "application/problem+json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
    };

    export function addSocialLink(model: TAddSocialLinkRequest) {
        const token: string = getAccessToken();
    
        return APIClient.request<
          TAddSocialLinkRequest,
          TAddSocialLinkResponse
        >({
            method: "POST",
            url: "/cm/pages_social_link",
            data: model,
            headers: {
                "Content-Type": "application/problem+json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
    };

    export function updateSocialLinksPosition(model: TSocialLinkPositionList) {
        const token: string = getAccessToken();
    
        return APIClient.request<
          TSocialLinkPositionList,
          any
      >({
          method: "PATCH",
          url: "/cm/pages_social_link",
          data: model,
          headers: {
            "Content-Type": "application/problem+json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
      });
    };
};
