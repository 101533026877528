import React from "react";

import { RegisterRequest } from "src/types";
import styled from "styled-components";
import letter from 'src/assets/img/letter.svg';
import { Txt } from "src/ui/text";
import { BackgroundColors, Colors, TextColors } from "src/constants";
import { Button } from "src/ui/buttons/Button";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "src/ui/checkbox";
import { useTranslation } from "react-i18next";
import { AuthService } from "src/services/api/services/AuthService";
import { useAuth } from "src/store/useAuth";
import shallow from "zustand/shallow";
import { LoginResponse } from "src/services/api/models/LoginResponse";
import { useIsMounted } from "src/hooks/useIsMounted";

type TProps = Readonly<{
  data: RegisterRequest;
  clearData: () => void;
}>;

const Container = styled.div`
    width: 100%;
    text-align: center;
`

const Icon = styled.button`
    background-image: url(${letter})};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 180px;
    width: 180px;
    background-color: transparent;
    display: block;
    margin: 0px auto;
`

const CheckboxContainer = styled.div`
    padding: 93px 20px 0;
`

const loginAsync = async (username: string, password: string): Promise<LoginResponse | undefined> => {
  try {
    const { request } = AuthService.login({
      username,
      password
    });

    const response = await request();
    const result = response.data.data;

    if (response.data.status !== 1 || result === undefined) {
      throw Error("Failed request");
    }

    return result;
  } catch (Error) {
    console.log(Error);
  }
}

const Confirmation_: React.FC<TProps> = ({
  data,
  clearData
}: TProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const {
    setTokenType,
    setAccessToken,
    setRefreshToken,
    clear
  } = useAuth((state) => ({
    setTokenType: state.setTokenType,
    setAccessToken: state.setAccessToken,
    setRefreshToken: state.setRefreshToken,
    clear: state.clear
  }), shallow);

  const [isTermAccepted, setIsTermAccepted] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const registerAction = React.useCallback(
    () => {
      (async () => {
        try {
          if (isMounted()) {
            setIsLoading(true);
          }

          const { request } = AuthService.register(data);
          const response = await request();

          if (response.status !== 200) {
            throw Error("Failed request");
          }

          const loginResponse = await loginAsync(data.email, data.password);

          if (loginResponse) {
            setTokenType(loginResponse.token_type);
            setAccessToken(loginResponse.access_token);
            setRefreshToken(loginResponse.refresh_token);

            clearData();
            navigate("/admin");
          }

          if (isMounted()) {
            setIsLoading(false);
          }
        } catch (Error) {
          console.log("register Error:", Error);
          if (isMounted()) {
            setIsLoading(false);
          }
        }
      })();
    },
    [clearData, data, isMounted, navigate, setAccessToken, setRefreshToken, setTokenType],
  );

  React.useEffect(
    () => {
      clear();
    },
    [clear]
  );

  return (
    <Container>
      <Icon />
      <Txt textStyle="h0" color={TextColors.dark1} marginTop="78px">{t('TITLE_VERIFY')}</Txt>
      <Txt textStyle="body_16" color={TextColors.dark3} marginTop="32px">{t('TEXT_VERIFY_ACCOUNT')}</Txt>
      <CheckboxContainer>
        <Checkbox
          value={isTermAccepted}
          onChange={setIsTermAccepted}
        >
          <Txt textStyle="body_14" color={TextColors.dark3} display="flex" flexWrap="wrap">
            I have read and agree to the <Txt color={Colors.sysBlue1}>&nbsp;Terms of use&nbsp;</Txt>of the service
          </Txt>
        </Checkbox>
      </CheckboxContainer>
      <Button
        isLoading={isLoading}
        isDisabled={!isTermAccepted}
        onClick={registerAction}
        label={t('BTN_CONTINUE') || undefined}
        buttonStyle="lg"
        margin="54px 0 0 0"
        spinnerColor={BackgroundColors.bgWhite}
      />
    </Container>
  );
};

export const Confirmation = React.memo(Confirmation_);
