import React from "react";

import { Controller } from "react-hook-form";
import c from "classnames";

import Input from "src/ui/inputs/Input";
import MainButton from "src/ui/buttons/MainButton";

import useSignUp from "./hook";
import s from "./style.module.sass";
import { LinkButton } from "src/ui/buttons/LinkButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RegisterRequest } from "src/types";

type TProps = Readonly<{
  onSubmit: (data: RegisterRequest) => void;
}>;

const SignUpForm: React.FC<TProps> = ({
  onSubmit,
}: TProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const {
    errors,
    control,
    isLoading,
    isDisabled,
    submit,
  } = useSignUp({
    onSubmit
  });
  
  const openSignIn = React.useCallback(
    () => {
      navigate("/signin");
    },
    [navigate],
  );
  
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Controller
        name="cardCode"
        control={control}
        render={({ field }) =>
          <Input
            type="text"
            name={field.name}
            value={field.value || ""}
            error={errors.cardCode}
            label={t('CARD_CODE')}
            className={c(s.input)}
            onBlur={field.onBlur}
            onChange={field.onChange}
          />
        }
      />
      <Controller
        name="username"
        control={control}
        render={({ field }) =>
          <Input
            type="email"
            name={field.name}
            value={field.value}
            error={errors.username}
            label={t('USER_EMAIL')}
            className={c(s.input)}
            onBlur={field.onBlur}
            onChange={field.onChange}
          />
        }
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) =>
          <Input
            type="password"
            name={field.name}
            value={field.value}
            error={errors.password}
            label={t('TITLE_PASSWORD')}
            className={c(s.input)}
            onBlur={field.onBlur}
            onChange={field.onChange}
          />
        }
      />
      <MainButton
        className={c(s.button)}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onClick={submit}
      >
        {`${t('BTN_CREATE')}`}
      </MainButton>
      <LinkButton 
        className={c(s.button)} 
        label={t('BTN_HAVE_ACCOUNT')}
        onClick={openSignIn} 
      />
    </form>
  )
};

export default SignUpForm;
