import { render } from "react-dom";
import { initI18n } from "src/i18n";
import { BrowserRouter } from "react-router-dom";

import App from "src/App";
import "src/assets/sass/common.sass"

(async () => {
  // init i18next stack
  try {
    await initI18n();
  } catch(e) {
    console.error(`Failed init i18next stack — ${e}`);
  }

  // start React app
  try {
    const rootElement = document.getElementById("app");
  
    if (rootElement === null)
      throw Error("HTMLElement with \"app\" id not found");
  
    render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      rootElement
    );
  } catch(e) {
    console.error(e);
  }
})();
